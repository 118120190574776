/* ==========================================================================
Utilities
  > Color
========================================================================== */

// ------ Text

.u-color--text {
  color: var(--color--text) !important;
}

.u-text-color--white {
  color: #fff !important;
}

.u-text-color--blue {
  color: var(--color--secondary);
}

.u-text-color--dark-blue {
  color: var(--color-secondary) !important;
}

.u-text-color--red {
  color: var(--color--red) !important;
}

.u-text-color--grey {
  color: var(--color-grey--light) !important;
}

.u-color--primary {
  color: var(--color--primary) !important;
}

.u-color--primary--light {
  color: var(--color--primary--light) !important;
}

@include bp-medium {
  .u-color--primary--light--m {
    color: var(--color--primary--light) !important;
  }
}

.u-color--white {
  color: #fff !important;
}

// ------ Opacity

.u-opacity--80 {
  opacity: 0.8;
}
