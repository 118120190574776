/* ==========================================================================
   #LINKBAR
   ========================================================================== */

.c-linkbar {
  display: none;
  padding: 13px 0;
  background: #fff;
  border-top: 1px solid var(--color--grey--light);
  border-bottom: 1px solid var(--color--grey--light);

  @include bp-medium {
    display: block;
  }

  ul {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin: 0;
    list-style-type: none;
    font-size: 18px;

    li {
      &:first-child {
        margin-right: auto;
      }
      a {
        @media (hover) {
          &:hover {
            color: var(--color--primary--hover);
          }
        }
      }
    }
  }
}
