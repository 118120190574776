/* ==========================================================================
Settings
  > Typography
========================================================================== */

:root {
  --fluid--16-20--375-1440: clamp(1rem, 0.912rem + 0.3756vw, 1.25rem);
  --font--headings: 'Inter', sans-serif;
  --font--body: 'Inter', sans-serif;
  --font--awesome: 'Font Awesome 6 Pro';

  @supports (font-variation-settings: normal) {
    --font--body {
      font-family: 'Inter var', sans-serif;
    }
  }
}
