/* ==========================================================================
   Tools
     > Containers
   ========================================================================== */

@mixin t-container {
  @include clearfix;
  width: 100%;
  max-width: calc(1280px + var(--gutter) * 2);
  margin: auto;
  padding: 0 var(--gutter);
  position: relative;

  .row {
    margin-left: calc(var(--gutter) / 2 * -1);
    margin-right: calc(var(--gutter) / 2 * -1);
  }
}

@mixin t-container--extended {
  padding-top: 40px;
  padding-bottom: calc(40px - 24px);

  @include bp-xlarge {
    padding-top: 80px;
    padding-bottom: calc(80px - 24px);
  }

  @include clearfix;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: calc(1280px + var(--gutter) * 2 + 256px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacer--alt);
}

@mixin t-container--restricted {
  max-width: calc(844px + var(--gutter) * 2);
  // margin-inline: auto;
}
