/* ==========================================================================
Settings
  > Colour
========================================================================== */

:root {
  // Text
  --color--text: hsl(0, 0%, 13%);

  // Primary

  --color--primary: rgba(0, 127, 199, 100%);
  --color--primary--hover: #007bc7;
  --color--primary--dark: #152644;
  --color--primary--transparency: rgba(0, 127, 199, 0.86);
  --color--primary--light: #d6eaf6;
  --color--primary--light--transparency: rgba(0, 127, 199, 16%);
  --color--primary--lightest--transparency: rgba(0, 127, 199, 8%);
  --color--primary--lightest: hsl(202, 67%, 95%);

  // Secondary

  --color--secondary: #1bbfce;

  // Tertiary

  --color--tertiary: #00a191;
  --color--tertiary--dark: #01564e;
  --color--tertiary--link: #008577;
  --color--tertiary--grey: #ebebeb;

  // Green

  --color--green: var(--color--tertiary);

  // Greys

  --color--grey: #ccc;
  --color--grey--medium: #c8c8c8;
  --color--grey--dark: #757575;
  --color--grey--light: #ddd;
  --color--grey--lighter: #f7f7f7;
  --color--border: var(--color--grey--light);

  // Blacks

  --color--off-black: #222;

  // Link

  --color--link: var(--color--primary);

  // Warning

  --color--red: #cc0b0b;

  //Articles

  --color--blog: #e9580d;
  --color--case-study: var(--color--primary);
  --color--event: var(--color--tertiary);
  --color--news: #e5007d;
  --color--webinar: #d60a26;
}
