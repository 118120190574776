/* ==========================================================================
Utilities
  > Outline
========================================================================== */

.u-outline--grey {
  outline: 1px solid var(--color--grey);

  &:hover {
    outline: 1px solid var(--color--grey);
  }
}
