/* ==========================================================================
Elements
  > Body
========================================================================== */

body {
  color: var(--color--text);
  font-size: var(--fluid--16-20--375-1440);
  font-family: var(--font--body);
  line-height: 1.6;
  font-weight: 400;
  overflow-x: hidden; // for no 'clip' support = no sticky
  overflow-x: clip; // = sticky ok
}

#__bs_notify__ {
  display: none !important;
}
