/* ==========================================================================
Components
  > Media
========================================================================== */

// 50 / 50

.c-media--50-50 {
  &--inverted {
    @include bp-medium {
      display: flex;

      .col:first-child {
        order: 2;
      }
    }
  }
}

@include bp-large {
  .c-article--main {
    &.l5 {
      .c-media--50-50 {
        .col {
          @include t-reset--col--100;
        }
      }
    }
  }
}

// Inherited

// Blocks

.mediaBlock:not(.mediaBlock--feature) {
  border-left: 7px solid var(--color--grey);
  margin-bottom: var(--gutter);
  padding-left: var(--gutter);

  figure {
    margin-bottom: 0;

    img {
      width: auto;
      max-height: 120px;
    }

    @include bp-medium {
      text-align: center;
      float: left;
      margin: 6.25px 4% 0 0;
      margin-bottom: 0px;
      margin-bottom: 8px;
      max-width: 35%;
    }
  }

  .h3 {
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 12px;
  }

  @include bp-medium {
    border-left: 0;
    padding-left: 0;
    overflow: hidden;
  }
}
