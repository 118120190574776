/* ==========================================================================
Components
  > Search
    > Overlay
========================================================================== */

// Mobile

.c-search__overlay {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: var(--gutter);
  display: none;

  .c-site-search {
    position: relative;
    z-index: 11;
    max-width: 844px;
    margin: var(--header__height) auto 0;
  }

  button.c-search-btn {
    position: absolute;
    right: 0;
    bottom: 25px;
    background: none;
    color: #222;
    border: 0;
    font-size: 1.5rem;
    cursor: pointer;

    span {
      color: #222;
    }
  }

  .c-modal__scrim {
    background: rgba(255, 255, 255, 0.9);
  }
}
