/* ==========================================================================
   #NORMALIZE
   ========================================================================== */

@import 'normalize-scss/sass/normalize/import-now';

b,
strong {
  font-weight: 600;
}
