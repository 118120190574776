/* ==========================================================================
Settings
  > Global
========================================================================== */

@import '../tools/_t-breakpoints';

:root {
  --interpolate-size: allow-keywords;
  --gutter: 28px;
  --gutter--alt: 36px;
  --gutter--big: 64px;
  --header__height: 64px;
  --header__pt: 0px;
  --main-search__input__height: 56px;
  --main-search__button__width: 56px;
  --transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --global__transition: all 200ms var(--transition-timing-function);
  --global__radius: 0px;
  --c-form__radius: 0px;
  --wrapper__padding: 40px;
  --wrapper__padding--minor: 16px;
  --wrapper__padding--major: 64px 0 56px;
  --spacer: 48px;
  --spacer--alt: calc(40px - 16px);
  --tap-target__size: 48px;
  --thumb__width: 177px;
  --thumb__height: 177px;
  --btn__padding: 16px;
  --anchor-menu__height: 64px;
  --header-padding-inline: var(--gutter);

  @media (min-width: 600px) {
    --spacer: 64px;
  }

  @media (min-width: 768px) {
    --wrapper__padding: 48px;
    --wrapper__padding--minor: 32px;
    --spacer: 80px;
    --spacer--alt: calc(64px - 16px);
    --main-search__input__height: 80px;
    --main-search__button__width: 190px;
  }

  @media (min-width: 960px) {
    --header__height: 112px;
    --header__pt: 20px;
    --wrapper__padding: 80px;
    --wrapper__padding--minor: 56px;
    --spacer: 96px;
    --spacer--alt: calc(112px - 16px);
  }

  @media (min-width: 1920px) {
    --wrapper__padding: 128px;
  }

  @include bp-xxlarge {
    --header-padding-inline: 2rem;
  }
}
