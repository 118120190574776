.c-anchor-menu {
  display: flex;
  align-content: center;
  position: sticky;
  top: 0;
  height: var(--anchor-menu__height);
  margin-bottom: 24px;
  background: var(--color--tertiary);
  z-index: 9;

  @include bp-xlarge {
    margin-bottom: var(--gutter--big);
  }

  .o-container {
    height: 100%;
  }

  nav {
    @include t-scrollbar(
      var(--color--tertiary--dark),
      var(--color--grey--light)
    );
    padding-top: 14px;
    height: 100%;
    scroll-snap-type: x mandatory;

    &.is--scroll {
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }
  ul {
    display: flex;
    column-gap: 10px;
    margin-block: auto;

    > li {
      scroll-snap-align: start;
    }
  }
  a {
    white-space: nowrap;
    color: #fff;
    padding: 2px 12px;
    border-radius: 4px;

    &.active {
      background-color: rgba(255, 255, 255, 0.16);
    }

    @media (hover) {
      &:hover {
        background-color: rgba(255, 255, 255, 0.16);
      }
    }
  }
}
