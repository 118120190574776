/* ==========================================================================
   Tools
     > Tables
   ========================================================================== */

// Responsive

@mixin t-table--responsive {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding: 0;
  margin-bottom: 2rem;

  .c-table,
  table {
    width: 100%;
    margin-bottom: 0;
    font-size: 16px;
  }
}

// Bordered

@mixin t-table--bordered() {
  th {
    color: var(--color--primary);
  }
  tbody {
    th,
    td {
      background: #fff;
      border: 1px solid #cecece;
    }
  }
}

// Compact

@mixin t-table--compact() {
  tr,
  th,
  td {
    padding: 0 16px;
    height: 48px;
  }
}

// Striped

@mixin t-table--striped() {
  th,
  td {
    border: none;
  }

  > tbody {
    > tr:nth-child(even) > td {
      background-color: #fff;
    }
  }
}

// Outline

@mixin t-table--outline() {
  position: relative;
  border-bottom: 1px solid var(--color--grey);

  th,
  td {
    background: transparent;
    border: 1px solid var(--color--grey);
  }

  th {
    color: var(--color--primary);
  }

  // // Set up base line style for table

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: var(--color--grey);
  }

  // // Top

  &:before {
    top: 0;
    height: 1px;
    width: 100%;
  }

  // // Right

  &:after {
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
  }

  tr,
  th,
  td {
    position: relative;
    border: none;

    &:before {
      content: '';
      position: absolute;
      background: var(--color--grey);
    }
  }

  // // trs get a bottom line

  // tr:before {
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 1px;
  // }

  // // th & td get a left line

  th,
  td {
    &:before {
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
    }
  }
}
