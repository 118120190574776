/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */

.c-breadcrumb {
  display: flex;
  padding-block: 0.5rem;
  padding-inline: var(--header-padding-inline);
  background: var(--color--grey--lighter);
  border-bottom: 1px solid var(--color--grey--light);

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: none;

    @include bp-max-large {
      &.c-breadcrumb--alt {
        display: block;

        li:not(.c-page--current),
        span {
          display: none;
        }
      }
    }

    li {
      display: inline-flex;
      align-items: center;
      font-size: 16px;

      a {
        &:hover {
          color: var(--color--primary);
        }

        &:focus-visible {
          outline-offset: 2px;
        }
      }
    }

    // Toolbar

    &.c-toolbar {
      display: flex;
      border-top: none;
      width: auto;
      display: block;
      margin: 0 0 0 auto;
      padding: 0 0 0 32px;

      > li {
        position: relative;
        padding: 0;
        margin: 0 0 0 12px;
        font-size: 0.875rem;
      }

      .fal {
        margin-left: 4px;
      }
    }
  }

  @include bp-medium {
    [aria-label='breadcrumbs'] ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .fa-chevron-right {
    display: inline-flex;
    font-size: 10px;
    margin-inline: 8px;
    position: relative;
    top: 1px;
  }
}

// Print / Share

.c-print,
.c-share {
  button {
    background: 0 0;
    border: 0;
    padding: 0;
    color: #343434;
    line-height: 1.2;
    cursor: pointer;

    @media (hover) {
      &:hover {
        color: var(--color--primary--hover);
      }
    }

    &:focus-visible {
      outline-offset: 2px;
    }

    .c-btn__text {
      display: none;

      @include bp-medium {
        display: inline-block;
        font-size: 16px;
      }
    }
  }
}
