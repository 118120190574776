/* ==========================================================================
Tools
  > Lists
========================================================================== */

// Bullet Custom

@mixin t-list--bullet--blue {
  margin-left: 18px;

  ul {
    list-style-type: disc;
    margin: 8px 0 0 16px;
  }

  li {
    margin: 0;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &::marker {
      color: var(--color--primary);
    }
  }
}
