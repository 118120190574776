/* ==========================================================================
Utilities
  > Spacing
========================================================================== */

// Wrapper

.o-wrapper--padded {
  padding-top: var(--wrapper__padding) !important;
  padding-bottom: var(--wrapper__padding) !important;
}

.o-wrapper--padded--top {
  padding-top: var(--wrapper__padding) !important;
}

.o-wrapper--padded--bottom,
.u-padded--bottom--wrapper {
  padding-bottom: var(--wrapper__padding) !important;
}

.o-wrapper--padded--minor {
  padding-top: var(--wrapper__padding--minor) !important;
  padding-bottom: var(--wrapper__padding--minor) !important;
}

.o-wrapper--padded--minor--top {
  padding-top: var(--wrapper__padding--minor) !important;
}

.o-wrapper--padded--minor--bottom {
  padding-bottom: var(--wrapper__padding--minor) !important;
}

.u-mt {
  margin-top: var(--wrapper__padding) !important;
}

// Gutter

.u-mt--gutter {
  margin-top: var(--gutter);
}

// Gutter

.u-mt--gutter {
  margin-top: var(--gutter) !important;
}

.u-mb--gutter {
  margin-bottom: var(--gutter) !important;
}

// Spacer

.u-mt--spacer {
  margin-top: var(--spacer) !important;
}

.u-pt--spacer {
  padding-top: var(--spacer);
}

.u-mb--spacer {
  margin-bottom: var(--spacer);
}

.u-mb--spacer--alt {
  margin-bottom: var(--spacer--alt);
}

// Padding - Gutter

.u-p--gutter {
  padding: var(--gutter);
}

.u-ml--half {
  margin-left: 4px !important;
}

.u-mb--half {
  margin-bottom: 4px !important;
}

.u-ml--auto {
  margin-left: auto;
}

.u-mr--auto {
  margin-right: auto;
}

// ------ Breakpoint specific

// @s

@include bp-small {
  .u-mb--0--s {
    margin-bottom: 0 !important;
  }
}

// @sm

@include bp-small-medium {
  .u-mb--0--sm {
    margin-bottom: 0 !important;
  }

  .u-pt--12--sm {
    padding-top: 96px !important;
  }
}

// @m

@include bp-medium {
  .u-mb--0--m {
    margin-bottom: 0 !important;
  }

  .u-mt--4--m {
    margin-top: 32px !important;
  }

  .u-pt--16--m {
    padding-top: 128px !important;
  }

  .u-pblock--16--m {
    padding-block: 128px;
  }
}

// @ml

@include bp-medium-large {
  .u-mt--2--ml {
    margin-top: 16px !important;
  }

  .u-mb--0--ml {
    margin-bottom: 0px !important;
  }

  .u-mb--2--ml {
    margin-bottom: 16px !important;
  }

  .u-mb--3--ml {
    margin-bottom: 24px !important;
  }

  .u-ml--auto--ml {
    margin-left: auto;
  }

  .u-pt--18--ml {
    padding-top: 144px !important;
  }

  .u-pl--8--ml {
    padding-left: 64px !important;
  }
}

// @l

@include bp-large {
  .u-mb--gutter--l {
    margin-bottom: var(--gutter) !important;
  }

  .u-mb--0--l {
    margin-bottom: 0 !important;
  }

  .u-mt--1--l {
    margin-top: 8px !important;
  }

  .u-mt--2--l {
    margin-top: 16px !important;
  }

  .u-mt--5--l {
    margin-top: 40px !important;
  }

  .u-mb--3--l {
    margin-bottom: 24px !important;
  }

  .u-mb--5--l {
    margin-bottom: 40px !important;
  }

  .u-mb--6--l {
    margin-bottom: 48px !important;
  }

  .u-pt--0--l {
    padding-top: 0;
  }

  .u-p--3--h--l {
    padding: 0 24px;
  }

  .u-mt--auto--l {
    margin-top: auto;
  }
}

// @xl

@include bp-xlarge {
  .u-mb--0--xl {
    margin-bottom: 0 !important;
  }

  .u-mb--gutter--xl {
    margin-bottom: var(--gutter) !important;
  }
}

// Max @s

@include bp-max-small {
  .u-mb--1--max--s {
    margin-bottom: 8px;
  }
}

// Max @sm

@include bp-max-small-medium {
  .u-margin-bottom--for-max-s-m {
    margin-bottom: calc(var(--gutter) / 2);
  }
}

// Max @m

@include bp-max-medium {
  .u-p--0--max--m {
    padding-top: 0;
  }

  .u-mb--0--max--m {
    margin-bottom: 0 !important;
  }

  .u-mb--1--max--m {
    margin-bottom: 8px !important;
  }
}

// Max @ml

@include bp-max-medium-large {
  .u-mb--0--max--ml {
    margin-bottom: 0 !important;
  }

  .u-mb--5--max--ml {
    margin-bottom: 40px !important;
  }
}

// Max @l

@include bp-max-large {
  .u-mb--0--max--l {
    margin-bottom: 0 !important;
  }

  .u-mb--2--max--l {
    margin-bottom: 16px !important;
  }

  .u-mt--4--max--l {
    margin-top: 32px !important;
  }

  .u-mb--6--max--l {
    margin-bottom: 48px !important;
  }
}

// Inherited

.u-margin-top {
  margin-top: 40px !important;
}

.u-margin-top-lg {
  margin-top: 80px !important;
}

.u-margin-bottom-lg {
  margin-bottom: 80px !important;
}

.u-margin-top-bottom-lg {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

@include bp-medium-large {
  .u-margin-top {
    margin-top: 64px !important;
  }

  .u-margin-top-lg {
    margin-top: 112px !important;
  }

  .u-margin-bottom-lg {
    margin-bottom: 112px !important;
  }

  .u-margin-top-bottom-lg {
    margin-top: 112px !important;
    margin-bottom: 112px !important;
  }
}
