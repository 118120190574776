.c-overlayer {
  position: fixed;
  inset: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  transition: opacity 0.2s ease-in-out;

  &.active {
    display: flex;
  }

  &__content {
    max-width: calc(100vw - var(--gutter) * 2);
    position: relative;
    padding: 48px var(--gutter);
    flex-basis: 70rem;
    max-height: calc(100vh - 6rem);
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.16);

    @include bp-medium {
      max-width: 75vw;
    }

    @include bp-xlarge {
      padding: 56px 80px;
      max-width: 960px;
    }

    a {
      text-decoration: underline;

      @media (hover) {
        &:hover {
          color: var(--color--primary--hover);
        }
      }
    }

    header {
      padding-bottom: 24px;
      margin-bottom: 40px;
      text-align: center;
      border-bottom: 1px solid var(--color--grey);

      h2,
      .h2,
      h3,
      .h3 {
        letter-spacing: 0;
      }
    }

    p + p {
      margin-top: 24px;
    }

    [data-overlayer-type='media'] & {
      background-color: unset;
      flex-basis: 100%;
      padding: 24px;
      overflow: hidden;
      border: none;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;

      section {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      margin: auto;
      height: 100%;
      min-height: 0;
      object-fit: contain;
      position: relative;
      z-index: 11;
    }
  }

  &__close {
    &.c-btn {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 12;
      width: 56px;
      font-size: 24px;
      background-color: #fff;
      text-align: center;

      @include bp-xlarge {
        top: 16px;
        right: 16px;
      }

      .fal {
        width: 24px;
        color: var(--color--text);
      }

      @media (hover) {
        &:hover {
          background-image: none;
          .fal {
            color: var(--color--primary--hover);
          }
        }
      }
    }
  }
}
