.c-subscribe {
  position: relative;

  &__content {
    position: relative;
  }

  &__heading,
  &__description {
    margin-bottom: 16px;
    color: #fff;
  }

  &__form {
    display: flex;
    max-width: 480px;
    margin: 40px auto 0;

    @include bp-max-medium {
      flex-wrap: wrap;
    }

    input {
      text-align: center;

      @include bp-medium {
        &[type='email'] {
          text-align: left;
        }
      }
    }

    .c-btn {
      min-width: 160px;
      width: 100%;

      @include bp-medium {
        width: 30%;
      }
    }
  }
}
