.c-split-screen {
  @include bp-max-large {
    padding-top: 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include bp-max-large {
      overflow: visible;
    }

    &::after,
    &::before {
      content: none;
    }

    @include bp-large {
      flex-direction: row;
    }
  }

  &__half {
    &:last-of-type {
      z-index: 1;
      @include bp-max-large {
        order: -1;
      }
    }
    @include bp-large {
      position: relative;
      width: 50%;
    }
  }

  &__text {
    @include bp-large {
      padding-right: 24px;
    }
  }

  &__figure {
    @include bp-max-large {
      margin-inline: calc(var(--gutter) * (-1));
    }
    @include bp-large {
      position: absolute;
      top: calc(var(--wrapper__padding) * (-1));
      width: calc(100vw / 2);
      height: calc(100% + 2 * var(--wrapper__padding));
    }
    figure {
      height: 100%;
      margin-bottom: 0;
      @include bp-max-large {
        aspect-ratio: 4/3;
        overflow: hidden;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
