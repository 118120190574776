/* ==========================================================================
Utilities
  > Backgrounds
========================================================================== */

// ============ Colours

// Bg (Primary)

.u-bg-color--primary {
  background-color: var(--color--primary) !important;

  .c-widget__header {
    color: #fff;
  }

  .c-btn:not(.c-btn--outline) {
    background-color: var(--color--primary--dark);
  }

  *:not(input),
  .c-btn--outline {
    color: #fff;
  }

  .c-btn--outline {
    border-color: #fff;

    &:hover {
      color: var(--color--text) !important;
      background-color: #fff;
    }
  }
}

.u-bg-color--primary--lightest {
  background-color: var(--color--primary--lightest) !important;

  *:not(input),
  .c-btn--outline {
    color: var(--color--text);
  }
}

.u-bg-color--primary--dark {
  background-color: var(--color--primary--dark) !important;

  *:not(input),
  .c-btn--outline {
    color: #fff;
  }
}

// Bg Colours (Secondary)

.u-bg-color--secondary {
  background-color: var(--color--secondary) !important;
}

// Bg Colours (Tertiary)

.u-bg-color--tertiary {
  background-color: var(--color--tertiary) !important;
}

// Bg Colours (Greys)

.u-bg-color--grey--lighter {
  background-color: var(--color--grey--lighter) !important;
}

// Articles

.u-bg-color--news {
  background-color: var(--color--news) !important;
}

// White

.u-bg--white,
.u-bg-color--white {
  background-color: #fff;
}

// Bg (Text)

.u-bg-color--text {
  background-color: var(--color--text) !important;

  *:not(input) {
    color: #fff !important;
  }

  .c-btn--outline {
    border-color: #fff;

    &:hover {
      color: var(--color--text) !important;
      background-color: #fff;
    }
  }
}

// Bg (Tertiary)

.u-bg-color--tertiary {
  background-color: var(--color--tertiary) !important;

  .c-btn:not(.c-btn--outline) {
    background-color: var(--color--tertiary--dark);
  }

  *:not(input) {
    color: #fff !important;
  }

  .c-btn--outline {
    border-color: #fff;

    &:hover {
      color: var(--color--text) !important;
      background-color: #fff;
    }
  }
}

// Stretch

.u-bg-color--stretch {
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 3000px;
    height: 100%;
    position: absolute;
    top: 0;

    // Not for sidebar pages

    @include bp-large {
      .c-article--main.l8 & {
        display: none;
      }
    }
  }

  // Not for sidebar pages

  @include bp-large {
    &.o-wrapper--has-bg {
      .c-article--main.l8 & {
        padding-left: var(--wrapper__padding);
        padding-right: var(--wrapper__padding);
      }
    }
  }

  &:before {
    left: -3000px;
  }

  &:after {
    right: -3000px;
  }

  &.u-bg-color--primary {
    &:before,
    &:after {
      background-color: var(--color--primary) !important;
    }
  }

  &.u-bg-color--primary--lightest {
    &:before,
    &:after {
      background-color: var(--color--primary--lightest) !important;
    }
  }

  &.u-bg-color--grey--lighter {
    &:before,
    &:after {
      background-color: var(--color--grey--lighter) !important;
    }
  }

  &.u-bg-color--text {
    &:before,
    &:after {
      background-color: var(--color--text) !important;
    }
  }

  &.u-bg-color--tertiary {
    &:before,
    &:after {
      background-color: var(--color--tertiary) !important;
    }
  }
}

// ============ Fit to Container

// Bg Images - Cover

.has--bg--img {
  @include bp-small {
    @include t-bg--img;
    padding: var(--wrapper__padding);
  }

  @include bp-max-small {
    background-image: none !important;
  }
}
