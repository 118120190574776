/* ==========================================================================
   Tools
     > Btns
   ========================================================================== */

// Base

@mixin t-btn {
  display: inline-flex;
  justify-content: center;
  font-family: var(--font--body);
  align-items: center;
  column-gap: 16px;
  margin: 0;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  background-color: var(--color--primary);
  overflow: hidden;
  padding: var(--btn__padding);
  line-height: 1.2;
  font-size: 20px;
  font-weight: 700;

  @media (hover) {
    &:not(.c-btn--link):not(.c-btn--img):not(.c-btn--outline):hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.08) 0 0); // Darken
      color: #fff;
      text-decoration: none !important;
    }
  }

  @include bp-small {
    width: auto;
  }
}

// Outline

@mixin t-btn--outline {
  background: transparent;
  border: 1px solid var(--color--text);
  //color: var(--color--primary);

  &:hover {
    color: #fff !important;
    background-image: none;
    background: var(--color--primary);
    border-color: var(--color--primary);
  }
}

// Outline - Secondary (Orange)

@mixin t-btn--outline--secondary {
  color: var(--color--secondary);
  border-color: var(--color--secondary);

  &:hover {
    color: #fff;
    background: var(--color--secondary);
  }
}

// Outline - Light

@mixin t-btn--outline--light {
  color: #fff;
  border-color: #fff;

  &:hover {
    color: #444 !important;
    background: #fff;
  }

  &:focus-visible {
    outline-color: #fff;
  }
}

// Header - Base

@mixin t-btn--header--base {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: var(--header__height);
  line-height: var(--header__height);
  text-align: center;
  text-decoration: none;

  @media (hover) {
    &:hover {
      color: var(--color--primary--hover);
    }
  }
}

// Header - text

@mixin t-btn--header__txt {
  display: none;

  @media (min-width: 375px) {
    display: flex;
    font-size: 16px;
  }

  @include bp-medium-large {
    display: none;
  }
}

// Header - icon

@mixin t-btn--header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 40px;
  height: 40px;
  text-align: center;

  @include bp-xlarge {
    width: 56px;
    height: 56px;
  }
}

@mixin t-btn--remove-default {
  background: none;
  border: none;
  text-align: left;
}
