.c-multicolumn-text {
  .col {
    margin-left: unset !important;
  }

  &__item {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .c-multicolumn-text--border-l & {
      padding: calc(var(--gutter) / 2) var(--gutter);
      border-left: 3px solid;
    }
    .c-multicolumn-text--border-primary & {
      border-color: var(--color--primary);
    }
    .c-multicolumn-text--border-secondary & {
      border-color: var(--color--secondary);
    }

    &[class*='u-bg-color--'] {
      padding: var(--gutter);
    }
  }

  &--columns {
    ul {
      margin-bottom: 0;
      @include bp-medium {
        column-count: 2;
      }
    }
  }

  @include bp-small {
    > .row {
      display: flex;
      flex-wrap: wrap;

      .c-multicolumn-text__item {
        height: 100%;
      }
    }
  }
}
