/* ==========================================================================
Utilities
  > Images
========================================================================== */

// Round

.u-image--round {
  border-radius: 100%;
}

.u-image__hold {
  display: flex;
  align-items: center;
  position: relative;
  /*
  @media (orientation: portrait) {
    min-height: 480px;
    padding-top: 50%;
    padding-bottom: 50%;
  }

  @media (orientation: landscape) {
    min-height: 320px;
    padding-top: 12%;
    padding-bottom: 12%;

    @include bp-large {
      min-height: calc(600px - var(--header__height) - var(--tap-target__size));
    }
  }
  */
}

.u-image__content {
  @include t-banner--ui;
  position: relative;
  z-index: 2;
}

/* ==========================================================================
  Text Alignment
========================================================================== */

// Centre

[data-component__text-alignment='centre'] {
  text-align: center;

  .u-image__content {
    margin-left: auto;
    margin-right: auto;

    > * {
      margin-left: auto;
      margin-right: auto;
    }

    .c-btn {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.u-image {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;

  > div {
    position: relative;
    height: 100%;
  }

  img {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
}

/* ==========================================================================
  Width
========================================================================== */

// Full

[data-component__width='wide'] {
  .u-image {
    width: 100vw;
    left: 50%;
    margin-left: -50vw;

    // Not for sidebar pages

    @include bp-large {
      .c-article--main.l8 & {
        width: 100%;
        left: auto;
        margin-left: 0;
      }
    }
  }
}
