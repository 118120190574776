/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  width: 100%;
}

// Minor

.o-wrapper--minor {
  padding: var(--wrapper__padding--minor) 0;
}

// Minor - Aside

@include bp-large {
  aside .o-wrapper {
    padding: var(--wrapper__padding--minor) 0;
    margin-bottom: var(--gutter);
  }
}

@include bp-medium {
  .o-wrapper--minor--m {
    padding: var(--wrapper__padding--minor) 0;
  }
}

// Major (@max sm)

@include bp-max-small-medium {
  .o-wrapper--major--max--sm {
    padding: var(--wrapper__padding--major);
  }
}

// Quick link

.o-wrapper--quick-link {
  padding: var(--wrapper__padding--quick-link);
}

// Hero

.o-wrapper--hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Tab

.o-wrapper--tab {
  display: flex;
  position: relative;
  flex-direction: column;

  @include bp-medium-large {
    flex-direction: row;
  }
}

// Sticky

.o-wrapper--sticky {
  position: sticky;
  top: 0;
  transition: all 0.4s;
  z-index: 9;

  .o-container {
    display: flex;
    align-items: center;
  }
}

.scroll-up .o-wrapper--sticky {
  top: 80px;

  @media (min-width: 1280px) and (max-height: 900px) {
    top: 0;
  }
}

.o-wrapper--has-bg {
  padding-top: var(--wrapper__padding);
  padding-bottom: var(--wrapper__padding);

  &:not(.u-bg-color--stretch) {
    padding-left: var(--wrapper__padding);
    padding-right: var(--wrapper__padding);
  }
}

.o-wrapper--relative {
  position: relative;
}

// Full width

.o-wrapper--full {
  left: calc(var(--gutter) * (-1));
  width: 100vw;
  @include bp-xlarge {
    left: calc((100vw - 1280px) / (-2));
  }
}

// 8 - 4 @l

.o-wrapper--8-4--l {
  &:before,
  &:after {
    display: none;
  }

  display: flex;
  flex-wrap: wrap;
  row-gap: var(--gutter);

  @include bp-large {
    row-gap: 0;

    .c-article__listing {
      width: 66.6666666667%;
      padding-right: calc(var(--gutter) * 1.5);
    }

    .c-btns {
      width: 33.3333333333%;
      align-self: flex-start;

      .c-btn {
        width: 100%;
        align-self: flex-start;
      }
    }
  }

  @include bp-xlarge {
    .c-article__listing {
      width: 75%;
    }

    .c-btns {
      width: 25%;
    }
  }
}
