/* ==========================================================================
Widgets
========================================================================== */

.w-widgets {
  > * {
    margin-top: var(--spacer);
  }
}

@include bp-large {
  .w-widget--product-app {
    .c-article--main.l8 & {
      .ml3 {
        width: 41.6666666667%;
      }
      .ml9 {
        width: 58.3333333333%;
      }
      .l4 {
        width: 50%;
      }
    }
  }
}

// Fix old floats / flex combination

[data-widget='i3.base.widget.image-caption'],
[data-widget='i3.base.widget.video-podcast'] {
  @include t-reset--die-floats-die;
}

.c-widget--image-text p:last-of-type {
  margin-bottom: 20px;
}
