.c-map {
  position: relative;
  height: 200px;

  @include bp-xlarge {
    height: 400px;
  }

  &__container {
    width: 100%;
    height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: calc(var(--gutter) * (-1));
    //     width: 100vw;
    //     height: 200px;

    //     @include bp-xlarge {
    //       left: calc((100vw - 100%) / (-2));
    //       height: 400px;
    //     }
  }
}
