/* ==========================================================================
   #Figures
   ========================================================================== */

.c-figure {
  position: relative;
  overflow: hidden;
  display: block;
  background-color: #fff;

  &__img {
    position: relative;
    width: 100%;
  }

  img {
    border: 1px solid var(--color--grey);
  }

  &[class*='c-figure-aspect-ratio--'] {
    img {
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      object-fit: cover;
    }
  }

  figcaption {
    padding: 1rem;
    line-height: 1.25;
    border-left: 5px solid #00a191;
  }

  &--inline {
    align-items: self-end;
    @include bp-max-medium {
      flex-wrap: wrap;
    }

    .c-figure__img {
      @include bp-medium {
        width: calc(50% - var(--gutter) / 2);
      }
    }
    figcaption {
      width: 100%;
      @include bp-medium {
        width: calc(50% + var(--gutter) / 2);
      }
    }
  }

  &--text-protected {
    figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-left: none;
      padding: 24px;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.85) 100%
      );
    }
  }

  // Aspect Ratios
  &.c-figure-aspect-ratio--4-3 {
    .c-figure__img {
      aspect-ratio: 4 / 3;
    }
  }
  &.c-figure-aspect-ratio--3-4 {
    .c-figure__img {
      aspect-ratio: 3 / 4;
    }
  }
  &.c-figure-aspect-ratio--3-2 {
    .c-figure__img {
      aspect-ratio: 3 / 2;
    }
  }

  // Ratios

  &.c-figure-ratio--4-3 {
    @include fluid-aspect(4 3);
  }

  &.c-figure-ratio--1-1 {
    @include fluid-aspect(1 1);
  }

  &.c-figure-ratio--3-4 {
    @include fluid-aspect(3 4);
  }

  &.c-figure-ratio--16-9 {
    @include fluid-aspect(16 9);
  }

  &.c-figure-ratio--16-10 {
    @include fluid-aspect(16 10);
  }

  &.c-figure-ratio--9-16 {
    @include fluid-aspect(9 16);
  }

  &.c-figure-ratio--13-6 {
    @include fluid-aspect(13 6);
  }

  &.c-figure-ratio--11-4 {
    @include fluid-aspect(11 4);
  }

  &.c-figure-ratio--31-10 {
    @include fluid-aspect(31 10);
  }

  &__action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Inherited

figure:not(.c-figure):not(.u-aspect) {
  position: relative;

  // Calendar

  &.figure--calendar {
    @include t-figure--calendar;
    @include t-figure--calendar__detail;
    position: relative !important;

    &.figure--calendar--sm {
      font-size: 26px;
      line-height: 1.3;
      max-width: 60px;

      .calendar-detail-mo,
      .calendar-detail-yr {
        font-size: 11px;
      }
    }
  }

  // Left

  &.figure--left {
    float: left;
    margin: 6.25px var(--gutter) 12.5px 0;
    max-width: 35%;
  }

  // Right

  &.figure--right {
    float: right;
    margin: 6.25px 0 12.5px var(--gutter);
    max-width: 35%;
  }

  // Small

  &.figure--sm {
    max-width: 80px !important;
  }

  // Video

  &.figure--video {
    @include fluid-aspect(16 9);
  }
}

video {
  max-width: 100%;
}
