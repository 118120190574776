/* ==========================================================================
   #FORM
   ========================================================================== */

// Shared

input,
select,
textarea {
  @include t-form--ui;
}

[type='submit'] {
  cursor: pointer;
  color: #fff;
  // border: 1px solid var(--color--primary);
  background-color: var(--color--primary);

  &:focus-visible {
    outline-offset: 2px;
  }
}

.c-form {
  @include t-form--ui--alt;

  &[id] {
    scroll-margin-top: calc(var(--header__height));
  }

  &.row {
    margin-left: calc(var(--gutter) / 2 * -1) !important;
    margin-right: calc(var(--gutter) / 2 * -1) !important;
  }
  .col {
    margin-bottom: 0;
  }

  input[type='password'] {
    font-family: caption;
    font-size: 16px;
  }

  p,
  label {
    a {
      text-decoration: underline;

      @media (hover) {
        &:hover {
          color: var(--color--primary--hover);
        }
      }
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    @media (max-width: 400px) {
      .c-btn {
        font-size: 18px;
      }
    }
  }

  &--mini {
    --border-color: var(--color--border);

    display: flex;
    // flex-grow: 1;
    justify-content: flex-end;

    .c-header__secondary & {
      gap: 0.5rem;
    }

    * {
      width: unset;
      height: 2rem;
      line-height: unset;
      font-size: 0.875rem;
      padding-inline: 0.5rem;
      border-radius: 0.5rem;

      @media (max-width: 374px) {
        font-size: 0.813rem;
      }
    }

    label {
      align-content: center;
      padding-inline: unset;
      flex-shrink: 0;
    }

    [type='text'] {
      --max-width: 10rem;
      width: 100%;
      max-width: var(--max-width);
      min-width: 4.25rem;
      // letter-spacing: 0.2ch;
      border: 1px solid var(--border-color);
      background-color: transparent;

      @media (prefers-reduced-motion: no-preference) {
        transition: border 200ms ease-in,
          max-width 200ms var(--transition-timing-function);
      }

      &:hover,
      &:focus-visible {
        --border-color: hsl(from var(--color--primary) h s l / 0.56);
      }

      &:focus-visible {
        --max-width: 18rem;
        outline: none;
        background-color: var(--color--primary--lightest);
      }
    }
  }
}

// 2 col

@include bp-medium {
  .c-form--2-col {
    display: flex;
    column-gap: var(--gutter);
    flex-wrap: wrap;

    .form-field {
      width: calc(50% - (var(--gutter) / 2));
    }
  }
}

button {
  font-family: var(--font--body);

  &:enabled {
    cursor: pointer;
  }
}

// Textarea

textarea {
  min-height: 192px;
  padding: 16px 24px;
  line-height: 1.5;
  resize: vertical;
}

// Select

.c-dropdown {
  position: relative;

  .far {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-style: normal;
    color: var(--color--text);
    pointer-events: none;
    z-index: 1;
  }

  // Restricted

  &.c-dropdown--restricted {
    display: flex;
    margin-bottom: 32px;

    @include bp-medium {
      margin-left: auto;
      max-width: calc(50% - var(--gutter) / 2);
    }

    @include bp-large {
      max-width: calc(66.666% - var(--gutter) / 2);
    }

    @include bp-xlarge {
      max-width: 390px;
    }
  }

  select {
    appearance: none;
    cursor: pointer;
    padding-right: 40px;
    padding-right: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    option {
      color: #444;
    }
  }
}

select::-ms-expand {
  display: none;
}

// Checkboxes & Radios

.checkbox,
.c-checkbox,
.ktc-checkbox,
.c-radio,
.ktc-radio {
  position: relative;
  margin: 0;
  display: block;
}

.checkbox input,
.c-checkbox input,
.ktc-checkbox input,
.c-radio input,
.ktc-radio input {
  @include hidden-visually;
}

.checkbox label,
.c-checkbox label,
.ktc-checkbox label,
.c-radio label,
.ktc-radio label {
  font-family: var(--font--body) !important;
  margin: 0 0 8px;
  position: relative;
  cursor: pointer;
  color: var(--color--text);
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
}

// Focus

.c-checkbox input:focus-visible + label:before,
.ktc-checkbox input:focus-visible + label:before,
.c-radio input:focus-visible + label:before,
.ktc-radio input:focus-visible + label:before {
  outline: 2px solid var(--color--primary);
  outline-offset: 2px;
}

.required-explanation {
  display: block;
  margin-bottom: 16px;
}

.form-field {
  margin-bottom: var(--gutter);

  .control-label {
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.required-field-red-star::after {
  content: '*';
  color: red;
}

/* Labels */

label {
  display: block;
}

.label-hidden {
  display: none;
}

// Radio

.c-radio label::before,
.ktc-radio label::before {
  content: '';
  border: 2px solid rgba(22, 48, 41, 0.64);
  background: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 100%;
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
  top: 5px;
}

.c-radio input:checked + label::after,
.ktc-radio input:checked + label::after {
  position: absolute;
  content: '';
  top: 10px;
  left: 5px;
  display: block;
  background: var(--color--primary);
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.c-radio input:checked + label:before,
.ktc-radio input:checked + label:before {
  border-color: var(--color--primary);
}

// Checkbox

.checkbox,
.c-checkbox,
.ktc-checkbox {
  &:not(.c-checkbox--btn) {
    label {
      &:before {
        content: '';
        flex-shrink: 0;
        border: 2px solid rgba(22, 48, 41, 0.64);
        background: #fff;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        position: relative;
        top: 5px;
      }
    }

    input:checked {
      + label {
        &::after {
          font-family: 'FontAwesome';
          content: '\f00c';
          position: absolute;
          top: 6px;
          left: 4px;
          display: block;
          font-size: 13px;
        }
        &.u-type--16 {
          &::after {
            top: 7px;
          }
        }
      }
    }
  }

  // Button-like checkboxes e.g. documents-listing.html

  &.c-checkbox--btn {
    label {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0;
      padding: 8px 16px;
      border: 1px solid var(--color--grey--light);
      border-radius: 3px;

      .fal {
        font-size: 20px;
      }
    }

    input {
      &:focus-visible {
        + label {
          outline: 2px solid var(--color--primary--hover);
          outline-offset: 2px;
        }
      }
      &:checked {
        + label {
          background-color: var(--color--primary);
          border-color: var(--color--primary);
          color: #fff;
        }
      }
    }
  }
}

// Link Area Checkboxes (e.g. Residence Selector in 'Live With Us' Form)

.c-link__area {
  .c-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    label {
      padding: 0;
      width: 100%;
      height: 100%;

      &:before {
        @include shadow(1);
        border-color: #fff;
        width: 24px;
        height: 24px;
        left: auto;
        top: 16px;
        right: 16px;
      }
    }

    input:checked + label:after {
      font-size: 14px;
      top: 15px;
      left: auto;
      right: 21px;
    }

    input:checked + label:before {
      @include shadow(2);
    }

    input:focus + label:before {
      outline-offset: 0;
    }
  }
}

// Radio Boxes

.c-radio__box {
  display: inline-block;
  margin: 0 calc(var(--gutter) / 2);
  text-align: center;

  &.is-active .c-radio__content {
    border: 1px solid var(--color--primary);
  }

  .c-radio__content {
    position: relative;
    //color: var(--color--primary);
    border: 1px solid #dbdbdb;
    padding: 6px 16px 8px 16px;
    /*
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 55%
    );
    */
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    label {
      margin: 0;
      //color: var(--color--primary);
      font-size: 20px;

      @include bp-medium {
        font-size: 38px;
      }
    }

    &:hover {
      border: 1px solid var(--color--primary);
    }

    @include bp-medium {
      padding: 16px 32px 22px 32px;
    }

    input[type='radio'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      padding: 0;
      border-radius: 0;
      z-index: 5;
    }
  }
}

.c-radio__box--text {
  .c-radio__content {
    input {
      border: 0;
      border-radius: 0;
      background: 0;
      font-weight: 600;
      width: 120px;
      padding: 0;
      text-align: center;
      //color: var(--color--primary);
    }

    @include bp-medium {
      padding: 19px 32px 22px 32px;
      font-size: 2rem;
    }
  }
}

// Responsive Radio Groups

.c-radio__group--responsive {
  .c-radio__box {
    margin: 0;
    float: left;
    padding-left: calc(var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);
    width: 50%;

    @include bp-small {
      width: 25%;
    }
  }
}

// Radio Groups - Horizontal

@include bp-small {
  .c-radio__group--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;

    .c-radio {
      margin-right: 16px;
      margin-bottom: 4px;

      @include bp-small-medium-extended {
        margin-right: 28px;
      }
    }
  }
}

// Fieldset

fieldset {
  margin-bottom: 0.5rem;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: normal;
    color: var(--color--secondary);
  }

  legend {
    font-weight: 600;
    color: var(--color--text);
  }

  input,
  select,
  textarea {
    margin-bottom: 0.75rem;
  }

  .row {
    margin-bottom: 0;
  }
}

// File

input[type='file'] {
  height: auto;
  line-height: 1;
  padding: 15px 24px;
}

.ktc-file-component-wrapper > label {
  display: none;
}

/* Placeholders */

::placeholder {
  --color: hsl(from var(--color--text) h s l / 0.56);
  color: var(--color);
  letter-spacing: 0;
}

.WatermarkText {
  color: var(--color--grey--medium);
}

/* Textarea */
textarea.form-control::placeholder,
.form-group textarea.form-control::placeholder {
  line-height: inherit !important;
}

/* Placeholders - Search */
.c-search .form-control::placeholder,
.c-search .form-group .form-control::placeholder {
  font-size: 18px;
}

/* Captchas */

.CaptchaTextBox {
  text-align: center;
}

label[for*='GoogleRecaptchaV3Component'] {
  display: none;
}

label[for*='CurrentUrlComponent'] {
  display: none;
}

// Asterisk

.c-asterisk {
  padding-left: 20px;
  position: relative;
  font-size: 0.875rem;

  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
    //color: var(--color--primary);
    font-size: 1.2rem;
  }
}

// Validation
.field-validation-error {
  display: flex;
  color: var(--color--red);
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

// Article Grid - labels

.c-g-map {
  position: relative;
  border: 1px solid #ddd;
  max-width: 640px;
  margin: 0 auto;

  .c-label {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 14px;
    background: #fff;
    height: 24px;
    line-height: 22px;
    padding: 0 8px;
    border: 1px solid #707070;
    border-radius: 16px;

    @include bp-small {
      font-size: 16px;
      bottom: 16px;
      right: 16px;
      height: 28px;
      line-height: 26px;
      padding: 0 12px;
    }
  }
}

// Floating Labels

.c-label--float {
  @include float-label-container;
  @include float-label;
  display: block;
  width: 100%;

  select {
    appearance: none;
  }

  input,
  select,
  textarea {
    @include float-label-input;

    @include float-label-scaled {
      font-size: 18px;
      color: var(--color--text);
      top: 13px;
      left: 24px;

      @media (min-width: 375px) {
        top: 12px;
        font-size: 20px;
      }
    }
  }

  textarea {
    + label {
      font-size: 18px;
      color: var(--color--text);
      top: 13px;
      left: 24px;

      @media (min-width: 375px) {
        top: 12px;
        font-size: 20px;
      }
    }
    &:focus + label {
      top: -32px;
      left: 0;
    }
  }

  textarea {
    &:placeholder-shown:not(:focus) + * {
      top: 16px;
    }
  }

  // Large e.g. Search Overlay

  &.c-label--float--l {
    @include float-label--l;

    input {
      @include bp-medium {
        font-size: 28px;
      }

      @include float-label-scaled {
        @include bp-medium {
          font-size: 28px;
          top: 26px;
          left: 28px;
          line-height: 1.2;
        }
      }

      &:focus {
        @include shadow(2);
      }
    }
  }

  // Small

  &.c-label--float--s {
    @include float-label--s;
  }

  &.c-label--has-button {
    label {
      right: 100px;
    }

    input {
      width: calc(100% - 96px);
    }
  }

  .control-label {
    font-weight: 400;
  }
}

.grecaptcha-badge {
  @include hidden-visually();
  right: 0 !important;
}

// Filters - Top

.c-top-filters {
  .o-container {
    padding: 0;
  }

  .row {
    margin-bottom: 0;

    .col:last-child {
      margin-bottom: 0;
    }
  }

  .col.u-flex {
    gap: 16px;
  }

  .c-checkbox.c-radio--horizontal {
    height: 56px;

    label {
      margin-bottom: 0;
    }
  }
}

// Layout - 100

.c-hero[data-article-hero__layout='100'] {
  .c-filter__direction {
    text-align: center;
    width: 100%;
  }
}

//validation on email field
.form-field {
  .explanation-text {
    font-size: 16px;
  }
}

// Elevated e.g. SEO Landing

.c-form--elevated {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  position: relative;
  padding: 40px 24px;
}
