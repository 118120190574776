/* ==========================================================================
Objects
  > Main
========================================================================== */

main {
  &.o-wrapper {
    padding-top: var(--wrapper__padding);

    > .o-container {
      padding-bottom: var(--wrapper__padding);
    }
  }
}
