/* ==========================================================================
Utilities
  > Display
========================================================================== */

.u-display--block {
  display: block;
}
.u-display--inline {
  display: inline;
}

.is-visible {
  display: block !important;
}
