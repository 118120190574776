/* ==========================================================================
Components
  > Header 
========================================================================== */

// Skip to Content

.skip-to-content-link {
  background: var(--color--primary--hover);
  height: 40px;
  left: 50%;
  top: 0px;
  padding: 8px;
  position: absolute;
  transform: translate(-50%, -1000px);
  transition: transform 0.3s;
  z-index: 999;
  color: #fff;
  font-size: 18px;

  &:focus {
    transform: translate(-50%, 0%);
  }

  &:hover,
  &:active {
    color: #fff;
  }
}

// Header

.c-header {
  --fluid--200-280--375-1440: clamp(12.5rem, 10.7394rem + 7.5117vw, 17.5rem);
  --logo-width: var(--fluid--200-280--375-1440);
  background: #fff;
  border-bottom: 1px solid var(--color--border);

  > * {
    padding-inline: var(--header-padding-inline);
  }

  &__secondary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
    padding-block: 0.5rem;
    border-bottom: 1px solid var(--color--border);

    @media (max-width: 479px) {
      gap: 0.75rem;
    }

    > div:not(.predictive-container) {
      display: flex;
      flex-wrap: nowrap;
      gap: 0.25rem;
    }
  }

  &__primary {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 2rem;

    @media (max-width: 1279px) {
      grid-template-columns: 1fr auto;
      padding-block: 0.5rem;
    }
  }

  // Logo

  .c-logo {
    justify-self: flex-start;

    img {
      width: var(--logo-width);
      min-width: 8rem;
    }
  }

  &__right {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
  }
}

.c-menu-btn {
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  color: var(--color--grey--dark);

  @include bp-large {
    display: none;
  }
  &.active {
    color: var(--color--grey);
  }

  &:hover {
    text-decoration: none;
  }
}

// Buttons

.c-btn--header {
  @include t-btn--header--base;

  .c-btn__text {
    @include t-btn--header__txt;
  }

  .c-btn__icon {
    @include t-btn--header__icon;
  }
}

// Site Search

.c-site-search {
  position: relative;

  &.c-search--primary {
    input[type='text'] {
      @include shadow(4);
      padding-right: calc(var(--main-search__button__width) + var(--gutter));
      background: #fff;
      height: var(--main-search__input__height);
      line-height: var(--main-search__input__height);
      -webkit-appearance: none;
    }

    button {
      font-size: 20px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      cursor: pointer;
      border: none;
      color: #fff;
      background: var(--color--primary);
      width: var(--main-search__button__width);
      height: var(--main-search__input__height);
      line-height: var(--main-search__input__height);

      &:focus-visible {
        outline: none;

        .far {
          outline-offset: 16px;
          outline: 2px solid #fff;
          border-radius: 100%;
        }
      }
    }
  }
}

.c-sites-dropdown {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;

  i {
    font-size: 0.813rem;
  }

  a {
    display: flex;
    height: 100%;
    align-items: center;
    color: var(--color--text);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--color--text);
    font-size: 0.875rem;
    background: none;
    border: 0;
    padding-inline: 0;

    &:hover {
      text-decoration: none;
      color: #000;
    }

    .far {
      display: grid;
      place-items: center;
      width: 1.25rem;
      height: 2rem;
      font-size: 0.75rem;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 11;
      width: 100%;
      height: calc(100% + 1px);
    }
  }
}

.c-sites-dropdown {
  > ul {
    @include t-drop-menu--ui;
    top: calc(100% + 1px);
    left: 0;

    li a {
      font-size: 0.875rem;

      &:focus-visible {
        outline: none;
        color: var(--color--primary);
        background-color: var(--color--primary--lightest);
      }
    }
  }

  &[data-menu-size='small'] {
    > ul {
      max-width: 192px;
    }
  }

  button[aria-expanded='true'] {
    .far {
      transform: rotate(180deg);
    }
  }

  &[data-menu-position='right'] {
    > ul {
      left: unset;
      right: 0;
    }
  }
}

.c-sites-dropdown {
  > [aria-expanded='true'] {
    + ul {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--color--primary);
    }
  }

  // &:focus-within {
  //   > ul {
  //     display: block;
  //   }
  // }
}

// CTA Buttons

.c-cta-btns {
  a {
    font-size: 18.667px;

    &:last-child {
      margin-left: 16px;
    }
  }
}

.c-header--items {
  display: none;

  @include bp-large {
    display: block;
    font-size: 0px; // Removes whitespace
  }
}

.c-header-menu-wrapper {
  display: flex;
  margin-left: auto;
}
