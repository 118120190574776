/* ==========================================================================
Objects
  > Layout
========================================================================== */

@media (min-width: 960px) and (min-height: 812px) {
  main {
    position: relative;
    // z-index: 5;
  }
}

.o-main-content {
  margin: 30px 0;

  @include bp-medium {
    margin: 40px 0;
  }

  @include bp-large {
    margin: 60px 0;
  }

  .row {
    overflow: hidden;
  }
}

.o-narrow {
  max-width: 69rem !important;
  margin: auto;
}

.o-narrower {
  max-width: 62.5rem !important;
  margin: auto;
}

.o-narrowest {
  max-width: 43rem !important;
  margin: auto;
}

.o-rightrail {
  max-width: 30em;

  @include bp-large {
    padding-top: 3rem;
    float: left;
    width: 20%;
  }
}

.o-has-sidebar {
  padding-top: 3rem;

  @include bp-large {
    float: left;
    width: 75%;
    margin-left: 5%;
  }

  img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

// Cols

.o-half-col {
  width: 50%;
  overflow: hidden;
  float: left;
}

.o-quarter-col {
  float: left;
  width: 25%;
  overflow: hidden;
}

// Borders

.o-border-bottom {
  border-bottom: 1px solid #ddd;
}

.o-border-top {
  border-top: 1px solid #ddd;
}

.o-layout--sample {
  .col > div {
    padding: 16px 32px;
    background: #f5f5f5;
  }
}

// Sections

.c-article__body {
  section:not(:last-child) {
    margin-bottom: 48px;
  }
}
