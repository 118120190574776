/* ==========================================================================
Components
  > Search Results
========================================================================== */

#searchResults h3 {
  word-wrap: break-word;
}

.c-search-controls {
  width: 100%;
  background: #f9f8fb;
  padding: 2rem;
  border: 1px solid #ddd;

  form {
    @include clearfix();
  }
}

.c-search-controls input.c-search-box {
  width: 100%;
  background: #fff;
  display: block;
  margin-bottom: 0.5rem;
  max-width: none;
  border: 1px solid #ccc;

  @include bp-medium {
    width: 48%;
    float: left;
    margin-right: 2%;
  }
}

.c-search-controls input.c-search-box {
  border: 1px solid #ccc;
}

.c-search-controls .c-dropdown {
  width: 100%;
  background: #fff;
  display: block;
  margin-bottom: 0.5rem;
  max-width: none;

  @include bp-medium {
    width: 30%;
    float: left;
    margin-right: 0;
  }
}

.c-search-controls select {
  margin-right: 0;
}

.c-search-controls input.btn {
  clear: both;
  float: left;
  max-width: none;

  @include bp-medium {
    clear: none;
    width: 18%;
    float: left;
    margin-right: 0;
    margin-left: 2%;
  }
}

.c-search-tabs {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 8px;
  overflow: hidden;

  @include bp-medium {
    margin-bottom: 24px;
  }

  ul {
    display: flex;
    width: 100%;
    margin-bottom: 4px;
  }

  a {
    display: block;
    height: 50px;
    padding: 0 16px;
    line-height: 50px;
    text-decoration: none;
    white-space: nowrap;

    @include bp-medium {
      padding-inline: 24px;
    }

    &.c-list--active {
      position: relative;
      background: #f1f4f9;
      color: var(--color--primary--hover);

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: var(--color--primary);
      }
    }

    @media (hover) {
      &:hover {
        background: #f1f4f9;
        text-decoration: none;
      }
    }

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  &.is--scroll {
    overflow-x: scroll;
    @include t-scrollbar;

    a.c-list--active:after {
      content: none;
    }
  }
}

// Search Result Items
.c-search-result {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.c-search-result h3 {
  margin-bottom: 0;
}

.c-search-result h3 a {
  color: #444;
}

.c-search-result p {
  margin-bottom: 0;
}

.c-search-result p a {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
