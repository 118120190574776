/* ==========================================================================
Objects
  > Container
========================================================================== */

// Base

.o-container {
  @include t-container;
}

.c-col--100,
.c-article--main.l8 {
  .o-container--nested {
    padding-left: 0;
    padding-right: 0;
  }
}

// .c-col--fb {
//   .o-container {
.o-container--nested {
  padding-left: 0;
  padding-right: 0;
}
//   }
// }

// Full Width

.o-container--fw {
  max-width: none;
}

// Inner

.o-container--inner {
  padding: calc(var(--gutter) / 2);
}

// Restricted

.o-container--restricted {
  @include t-container--restricted;
}

.o-container .o-container {
  padding-inline: 0;
}

// Styles

.o-container--styles {
  border: 1px solid var(--color--grey);
  border-radius: var(--global__radius);

  .row {
    margin-bottom: 0;

    .col {
      padding: var(--gutter) !important;

      @include bp-large {
        margin-bottom: 0;
      }
    }
  }
}

// Alt

@media (min-width: 375px) and (max-width: 767px) {
  .o-container--alt {
    padding: 0 var(--gutter--alt);
  }
}

.o-container--extended {
  @include t-container--extended;
}

/* ==========================================================================
  Full Bleed
========================================================================== */

// May Exist

.c-article--main {
  .o-container--may-exist {
    @include t-container;
  }
}
