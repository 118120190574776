/* ==========================================================================
Tools
  > Modals
========================================================================== */

// Scrim

@mixin t-modal__scrim {
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  content: '';
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
