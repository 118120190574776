/* ==========================================================================
Components
  > Pagination
========================================================================== */

// hold, ul, li, select

.c-pagination__hold {
  display: flex;
  justify-content: center;
  margin: var(--spacer) 0 0;

  nav,
  .loadmore {
    display: none;
  }

  nav {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &.c-pagination--nav {
    nav {
      display: flex;
    }
  }

  &.c-pagination--button {
    .loadmore {
      display: block;
    }
  }

  &.c-pagination--mixed {
    @include bp-max-medium {
      .loadmore {
        display: block;
      }
    }
    @include bp-medium {
      nav {
        display: flex;
      }
    }
  }

  // ul

  .c-pagination {
    @include clearfix();
    width: auto;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      margin: 0 0 8px 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      border-radius: 50px;

      @include bp-medium {
        width: 48px;
        height: 48px;
        line-height: 48px;
        margin-right: 8px;
      }

      .far {
        font-size: 0.8rem;
      }
    }
  }

  // Select

  .c-dropdown {
    margin-top: 16px;

    @include bp-small {
      margin-top: -8px;
      margin-left: 8px;
    }
  }
}

// a, active

.c-pagination {
  > li > a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    text-decoration: none;
    color: #767676;
    border-radius: 50px;

    @include bp-medium {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }
}

// active

.c-pagination .active {
  background: var(--color--primary--hover);
  color: #fff;
}

// hover

.c-pagination > li > a:hover {
  color: #fff !important;
  text-decoration: none !important;
  background: var(--color--primary--hover);
}
