/* ==========================================================================
Objects
  > Sidebars
========================================================================== */

// Add default top margins to added elements

.o-sidebar {
  margin-left: 0 !important;

  &--right {
    @include bp-max-small {
      margin-inline: calc(var(--gutter) / (-2));
    }
    @include bp-large {
      order: 1;
    }
    margin-top: 0;
  }
}

// Right

.o-sidebar--r {
  margin-top: 32px;
  margin-bottom: 0 !important;

  @include bp-large {
    margin-top: 0;
  }
}
