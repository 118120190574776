/* ==========================================================================
Elements
  > html
========================================================================== */

html {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  &.is--overflow {
    overflow: hidden;
  }
}

body {
  background: #fff;
}

:focus-visible {
  outline-color: var(--color--primary--hover);
}
