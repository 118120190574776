/* ==========================================================================
   Tools
     > Navigation
   ========================================================================== */

// Border

@mixin t-nav-item__border {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  height: 3px;
  background: #007ec6;
  pointer-events: none;

  @include bp-xxlarge {
    left: 24px;
    right: 24px;
  }
}

// Drop Menus

@mixin t-drop-menu--ui {
  display: none;
  overflow: hidden;
  position: absolute;
  margin: 0;
  background: #fff;
  padding: 0.5rem;
  border-top: 0;
  z-index: 20;
  width: max-content;
  max-width: 18rem;
  list-style-type: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.16);

  li {
    a {
      display: block;
      text-decoration: none;
      font-size: 1rem;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;

      &:hover {
        background: var(--color--primary--lightest);
        text-decoration: none;
      }
    }
  }
}
