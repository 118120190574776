.c-distributors {
  margin: 0;
  list-style: none;

  li {
    &:not(:last-child) {
      .c-distributors__item {
        margin-bottom: var(--gutter);
        border-bottom: 1px solid var(--color--grey--light);
      }
    }
  }

  address {
    font-style: normal;
  }

  &__data,
  address {
    display: flex;
    justify-content: left;
    align-items: baseline;
    column-gap: 12px;
    margin-bottom: 0;
  }

  &__data {
    ~ .c-distributors__data--mail {
      @include bp-medium-large {
        margin-top: 32px;
      }
    }
  }

  a {
    word-break: break-all;
    @media (hover) {
      &:hover {
        color: var(--color--primary--hover);
      }
    }
  }

  &__heading {
    margin-bottom: 0;
    font-size: 22px;
    color: var(--color--primary);
  }

  &__subheading {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    margin: 0;
    li {
      display: inline-block;
    }
  }

  .fal {
    display: inline-flex;
    justify-content: center;
    color: var(--color--primary--hover);
    width: 24px;
    flex-shrink: 0;
  }
}
