/* ==========================================================================
Utilities
  > Borders
========================================================================== */

.u-border {
  border: 1px solid var(--color--grey--light);
}

.u-border--b {
  border-bottom: 1px solid var(--color--grey--light);
}

// Max

@include bp-max-large {
  // Bottom

  .u-border--b--for-max-l {
    border-bottom: 1px solid var(--color--grey--light);
  }
}

.u-b--l {
  border-left: 1px solid var(--color--grey--medium);
}

@include bp-large {
  // Right

  .u-border--r--for-l {
    border-right: 1px solid var(--color--grey--light);
  }
}

// Top

.u-bt,
.h-overline {
  border-top: 1px solid var(--color--grey--light);
}

// Top - light

.u-bt--light {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

// Top - Before

.u-bt--before {
  &:before {
    content: '';
    width: 100vw;
    height: 1px;
    position: absolute;
    top: 0;
    left: calc(var(--gutter) * (-1));
    border-top: 1px solid var(--color--grey--light);
    @include bp-xlarge {
      left: calc((100vw - 100%) / (-2));
    }
  }
}

// Bottom

.u-bb,
.h-underline {
  border-bottom: 1px solid var(--color--grey--light);
}

// Bottom - After

.u-bb--after {
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background: var(--color--grey--light);
  }
}

.h-underline {
  padding-bottom: 16px;
}

// None

.u-b--none {
  border: none;
}

// Border bottom - @l border right

.u-bb--r--l {
  border-bottom: 1px solid rgba(144, 157, 185, 0.33);

  @include bp-large {
    border-bottom: none;
    border-right: 1px solid rgba(144, 157, 185, 0.33);
  }
}

.u-heading--bb {
  display: inline-block;
  border-bottom: 3px solid var(--color--text);
  padding-bottom: 8px;
}

// Inherited

.h-underline {
  border-bottom: 1px solid var(--color--grey);
  padding-bottom: 12.5px;
}
