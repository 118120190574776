/* ==========================================================================
   Utilities
     > Width
   ========================================================================== */

// 80

.u-w--80 {
  width: 80px;
}

// Max 96

.u-mw--96 {
  max-width: 96px;
}

// Max 104

.u-mw--104 {
  max-width: 104px;
}

// Max 120

.u-mw--120 {
  max-width: 120px;
}

// Max 160

.u-mw--160 {
  width: 100%;
  max-width: 160px;
}

// Max 256

.u-mw--256 {
  max-width: 256px;
}

// Max 640

.u-mw--640 {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  float: none !important;
}

@include bp-small {
  .u-mw--50--s {
    max-width: 50%;
  }
}

// 100%
.u-w--full {
  width: 100%;
}

.u-min-w--480 {
  min-width: 480px;
}

@media (max-width: 679px) {
  .u-min-w--8--max--679 {
    min-width: 8rem !important;
  }
}
