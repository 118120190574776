/* ==========================================================================
   #LINKS
   ========================================================================== */

.c-link {
  display: block;

  &--icon {
    .far {
      margin-left: 8px;
    }
    .fal {
      margin-right: 8px;
      font-weight: 400;
    }
  }
}

.c-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 24px;
  font-size: 16px;
  transform: translateX(-4px);

  a {
    .fal {
      font-size: 15px;
      width: auto;
    }

    &:first-child {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }

    &:not(:first-child) {
      color: var(--color--tertiary--link);
    }
  }
}

// Polite

.c-link--polite {
  display: block;
  color: var(--color--text);
  font-size: 18px;
  margin-bottom: 16px;
}

// Main Article

// .c-article--main {
//   a:not(.c-btn):hover {
//     @include t-link__hover;
//   }
// }

// Shared

.c-link__result {
  text-decoration: none;
}

// Result cat

.c-link__result--cat {
  background: #fff;
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: var(--global__radius);
  text-align: center;
  float: right;
}

// Email

.c-email a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: #111;
}

.c-file__upload {
  position: relative;
}

// Area

.c-link__area {
  position: relative;
}

// Cover

.c-link--cover {
  color: var(--color--text);
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    @include t-link__hover;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0px;
    z-index: 2;
  }

  span.fas {
    position: relative;
    top: -8px;
    margin-left: 8px;
    font-size: 16px;
  }

  &:focus-visible {
    outline: none;

    &:after {
      outline: 2px solid var(--color--primary);
    }
  }

  h2 > & {
    font-weight: 600;
  }
}

button.c-link--cover {
  text-align: left;
}

// Featured Topics

.c-link--featured-topic {
  position: relative;
  border: 2px solid #eaeff6;
  padding: 32px;
  height: 100%;

  p {
    margin: 0;
  }
}

// Separate

.c-link--separate {
  display: block;
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid #ddd;
}

// Inherited

// More

.more {
  position: relative;
  padding-left: 26px;
  margin-left: 8px;

  // 3 dots

  &:before,
  &:after,
  span:before {
    content: '';
    position: absolute;
    bottom: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--color--primary);
  }

  &:before {
    left: 0;
  }

  &:after {
    left: 8px;
  }

  span:before {
    left: 16px;
  }

  // hover

  &:hover {
    &:before,
    &:after,
    span:before {
      background: var(--color--secondary);
    }
  }
}

// Colour - text

.c-link--color--text {
  color: var(--color--text);
}
