/* ==========================================================================
   #NAVIGATION - ADA MOBILE MENU
   ========================================================================== */

.nav {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Hamburger button */
.menu-toggle-button {
  background: none;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.menu-toggle-button .fas {
  width: 20px;
}

/** Mobile menu */
#mobile-menu-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;

  &:before {
    display: none;
  }

  @include bp-xlarge {
    display: none !important;
  }

  .c-modal__dialog {
    width: 100%;
    max-width: 300px;
  }

  .c-modal__content {
    width: 100%;
  }
}

/** Close button */
.close-button {
  color: var(--color--text);
  background: transparent;
  border: 0;
  border-radius: 16px;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: var(--tap-target__size);
  height: var(--tap-target__size);
  z-index: 2;

  &:focus-visible {
    outline: 0;
    background-color: var(--color--primary--lightest);
  }
}

.close-button .fas {
  width: 20px;
}

.menu-container {
  position: relative;
  left: -100%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  padding-top: 3rem;
  background-color: #fff;
}

.menu-container-overflow {
  height: 100%;
  padding-bottom: calc(64px + 16px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ada-menu {
  list-style: none;
  margin: 0;
  z-index: 2;
  width: 100%;

  .c-nav__divider {
    border-top: 1px solid var(--color--grey--light);
    margin-top: 10px;

    a {
      margin-top: 18px;
      color: var(--color--text);
      font-size: 16px;
    }
    ~ li {
      a,
      button {
        color: var(--color--text);
        font-size: 16px;
      }
    }
  }
}

.ada-menu {
  li {
    a,
    button,
    &.has--no-target {
      position: relative;
      display: flex;
      gap: 8px;
      width: 100%;
      padding: 0.7rem 2rem 0.7rem 1rem;
      font-size: 1rem;
      line-height: 1.3;
      text-decoration: none;
      text-align: left;
      color: var(--color--text);
      background: #fff;
      border: 0;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        outline: none;
        background: var(--color--primary--lightest);
      }
    }

    &.has--no-target {
      pointer-events: none;
    }

    .far {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      font-size: 18px;
    }

    &.c-nav__item--alt {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid var(--color--border);

      a {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.64px;
      }
    }
  }
}

/** Back button on level 1 menus */

.ada-menu li button.back-button {
  padding-left: 3rem;
  color: var(--color--text);

  .far {
    right: auto;
    left: 24px;
    transform: translateY(-50%);
  }

  &:focus-visible,
  &:hover {
    outline: none;
    background: var(--color--primary--lightest);
  }
}

/** Level 0 (top level) menu */
.menu-level-0 {
  position: absolute;
  left: 0;
  width: 100%;
}

/** Level 1 menu */
.menu-level-1 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-0 .ada-menu {
  display: block;
}

.menu-level-1 .ada-menu {
  display: none;
}

/** Level 2 menu */

.menu-level-2 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-2 .ada-menu {
  display: none;
}

/** Level 3 menu */

.menu-level-3 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-3 .ada-menu {
  display: none;
}

// Prevent page scroll

@media (max-width: 1279px) {
  .is--menu--mobile {
    body {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    .c-modal--menu {
      .c-modal__dialog {
        transform: translateX(0) !important;

        .c-modal__content {
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}

.c-menu__footer {
  margin-top: auto;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: #fff;
  border-top: 1px solid var(--color--grey--light);
  z-index: 1;

  .c-footer-social {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 0;

    li {
      list-style-type: none;

      a {
        display: block;
        width: var(--tap-target__size);
        height: var(--tap-target__size);
        line-height: var(--tap-target__size);
        text-align: center;
        color: var(--color--primary);

        &:hover,
        &:focus-visible {
          outline: 0;
          background-color: var(--color--primary--lightest);
          border-radius: 16px;
        }
      }
    }
  }
}
