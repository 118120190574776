/* ==========================================================================
   #LISTS
   ========================================================================== */

// Shared

.c-list {
  margin: 0;
  padding: 0;
  list-style: none;

  // No decoration

  &.c-list--no-dec {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // Icons

  &.c-list--has-icons {
    li {
      position: relative;
      padding-left: 40px;
      line-height: 1.6;
      margin-bottom: 8px;
    }
    .fal {
      position: absolute;
      top: 6px;
      left: 0;

      + a {
        display: inline-block;
        word-wrap: break-word;
      }
    }
  }

  // Search attachments

  &.c-list--has--icons {
    margin: 24px 0 0;
    border: 1px solid var(--color--grey--light);
    border-radius: 16px;

    @include bp-small-medium {
      width: 66.666%;
    }

    li {
      display: flex;
      gap: 12px;
      padding: 32px;

      &:not(:first-child) {
        border-top: 1px solid var(--color--grey--light);
      }
    }

    .far {
      position: relative;
      top: 3px;

      + a {
        display: flex;
        word-break: break-word;
        color: var(--color--text);
        opacity: 0.72;
        font-size: 18px;
        line-height: 24px;

        &:hover {
          color: var(--color--primary--hover);
          opacity: 1;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }

  &.communities {
    columns: 3;
    margin-top: 3rem;

    li {
      margin-bottom: 1rem;
    }
  }

  &--languages {
    display: flex;
    gap: 12px;
    margin-left: 4px;

    a {
      display: inline-flex;
      width: 24px;
      height: 16px;
    }
  }
}

// Icons Inline

.c-list--icons {
  list-style: none;
  margin-left: 0;

  li {
    display: inline;

    &::before {
      vertical-align: middle;
    }
  }

  &.c-list--icons--24 li:before {
    font-size: 24px;
  }
}

// Checklist

.c-list--checklist {
  li {
    &:before {
      font-family: 'icomoon' !important;
      //color: var(--color--primary);
    }
  }
}

.c-list--numberlist {
  padding: 0;
  margin: 0 0 2rem 0;
  counter-reset: counter;

  li {
    position: relative;

    list-style-type: none;
    margin-bottom: 1rem;
    padding-left: 45px;

    &:before {
      top: 0;
      left: 0;
      counter-increment: counter;
      content: counter(counter);
      background: var(--color--primary);
      color: #fff;
      height: 30px;
      width: 30px;
      display: block;
      position: absolute;
      text-align: center;
      line-height: 26px;
      font-size: 1.1rem;
      border-radius: 50px;
    }
  }
}

// Index Page

.c-list.c-list--index {
  margin-bottom: var(--gutter);
  border: 1px solid #ddd;
  border-radius: 16px;
  transition: box-shadow ease-in-out 200ms;

  &:hover {
    @include shadow(3);
  }

  li {
    a {
      display: block;
      padding: 16px;
      border-bottom: 1px solid #ddd;
      color: #333;
      text-decoration: none !important;

      &:hover {
        background: #f7f7f7;
        color: #444;
      }
    }

    &:first-child a {
      border-radius: 16px 16px 0 0;
    }

    &:last-child a {
      border-bottom: none;
      border-radius: 0 0 16px 16px;
    }
  }

  &.c-list--lonely li a {
    border-radius: 16px;
  }
}

// Separated

.c-list--separated,
.list--block {
  margin: 0;
  padding: 0;
  list-style: none;

  li:not(:last-child) {
    border-bottom: 1px solid var(--color--grey--light);
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  a {
    text-decoration: none;
    color: #444;

    &:hover {
      @include t-link__hover;
    }
  }
}

// Minor

.c-list--meta {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
  margin: 16px 0 0;

  a {
    position: relative;
    z-index: 3;
    text-decoration: none;

    @media (hover) {
      &:hover {
        color: var(--color--primary--hover);
      }
    }
  }

  .c-meta__cats {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    line-height: 24px;

    a {
      display: inline-flex;
      flex-shrink: 0;
      color: var(--color--primary);
      font-size: 13px;
      border: 1px solid rgba(0, 0, 0, 0.16);
      padding: 0 4px;
      border-radius: 4px;

      @media (hover) {
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
          text-decoration: none;
        }
      }
    }
  }

  li + .c-meta__cats {
    margin-top: 8px;
  }

  .c-meta__date__read-time,
  .c-meta__author-img__position {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .c-meta__read-time,
  .c-meta__source {
    color: #666;
  }

  .fa-clock {
    color: #000;
    margin-right: 4px;
  }

  .c-meta__author-img__position {
    align-items: center;
  }

  .c-meta__author a {
    font-weight: 600;
  }

  .c-meta__author-img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;
  }
}

// External

.c-list--external {
  font-size: 16px;

  li {
    padding-left: 38px;

    a {
      display: block;
      margin: 0 0 10px 0;
      color: var(--color--text);
      text-decoration: none;

      &:hover {
        color: #2e5797 !important;
      }

      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 8px;
        color: #2e5797;
      }
    }
  }
}

// Inline Block / Horizontal

.c-list--inline-b,
.list--horizontal {
  li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  @include bp-large {
    &.u-ta--r--ml {
      li {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }
}

// Bullet Custom

.c-list--bullet--blue {
  @include t-list--bullet--blue;
}

ul.c-list--bullet--blue {
  list-style-type: disc;
}

// Icon

.c-list--icon {
  padding-left: 24px;

  li {
    position: relative;
    margin-bottom: 4px;
  }

  .c-icon {
    position: absolute;
    top: 4px;
    left: -24px;
    color: var(--color--grey--dark);
  }
}

// Icon - Centred

.u-ta--c {
  .c-list--icon {
    .c-icon {
      position: relative;
      top: 2px;
      left: -4px;
    }
  }
}

// Margin bottom

.c-list--mb--12 {
  li {
    margin-bottom: 12px;
  }
}

// Counter

.c-list--counter {
  list-style: none;
  counter-reset: counter;
  margin-left: 0;
  padding-left: 0;

  li {
    counter-increment: counter;
    position: relative;
    padding-left: 32px;

    &:before {
      @include t-heading--h4;
      content: counter(counter) '. ';
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}

// Inherited

// Unstyled

.list--unstyled {
  list-style: none;
  padding: 0;
  margin-left: 0;
}

// Horizontal

.list--horizontal {
  margin-left: 0;

  &.list--separated {
    .fal {
      margin-left: 16px;
    }
  }
}

// Info List w/ icons

.list--info {
  list-style: none;
  margin-left: 0;

  li {
    margin-bottom: 6.25px;

    .fal {
      margin-right: 12px;
    }
  }
}

.c-list--horizontal {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

// Cards

ul.row {
  list-style: none;
}

// Flex

.c-list--flex {
  display: flex;
  margin-bottom: 0 !important;

  &:last-child {
    margin-bottom: 16px !important;
  }

  li:last-child {
    margin-left: auto;
  }
}

// Flex - m

.c-list--flex--m {
  margin-bottom: 8px !important;

  &:last-child {
    margin-bottom: 24px !important;
  }

  @include bp-medium {
    display: flex;

    &:last-child {
      margin-bottom: 0 !important;
    }

    li:first-child {
      width: 82px;
      flex-shrink: 0;
    }
  }
}

// Share

.c-list--share {
  background: var(--color--primary);
  border-radius: 6px;
  list-style: none;
  margin: 0;

  // In toolbar

  .c-share & {
    display: none;
    width: 50px;
    padding: 10px 0 4px 0 !important;
    text-align: center;
    z-index: 11;
    position: absolute;
    top: 49px;
    left: -12px;

    li {
      width: 100%;
      display: block;

      a {
        width: 80%;
        display: block;
        margin: 0 auto 4px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: -7px;
      left: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--color--primary) transparent;
    }

    @include bp-medium {
      left: 10px;
    }
  }

  // In body

  .c-article__body & {
    display: inline-flex;
    margin-bottom: 40px;
    padding: 8px;

    li a {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }

  > li {
    text-align: center;
    padding: 0;

    > a {
      color: #fff;
      border-radius: 6px;

      &:hover {
        color: var(--color--primary);
        background: var(--color--primary--light);
        text-decoration: none;
      }
    }
  }

  .active & {
    display: block;
  }
}

// A Z

.c-list__browse--az {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 64px;
  scroll-margin-top: calc(var(--header__height) + 32px);

  li {
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: var(--tap-target__size);
      height: var(--tap-target__size);

      &:hover {
        color: #fff;
        background-color: var(--color--primary);
      }
    }
  }
}
// Glossary

.c-list--glossary {
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    scroll-margin-top: calc(var(--header__height) + 32px);

    &:not(:first-child) {
      margin-top: 40px;
    }

    @include bp-max-medium {
      flex-direction: column;
    }

    @include bp-medium {
      gap: 40px;
    }
  }

  .c-list--glossary__letter {
    @include t-heading--h1;
    margin-bottom: 16px;
    align-self: flex-start;

    @include bp-medium {
      position: sticky;
      top: 32px;
    }
  }

  dl {
    margin: 8px 0 0;
  }

  dd {
    margin: 0 0 40px 0;
  }

  .c-btn {
    align-self: flex-end;
    flex-shrink: 0;
    font-weight: 400;
  }
}
