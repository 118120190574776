.c-label {
  display: inline-block;
  padding: 4px;
  margin-right: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;

  &--blog {
    background-color: var(--color--blog);
  }
  &--case-study {
    background-color: var(--color--case-study);
  }
  &--event {
    background-color: var(--color--event);
  }
  &--news {
    background-color: var(--color--news);
  }
  &--webinar {
    background-color: var(--color--webinar);
  }
  &--online {
    color: var(--color--text);
    background-color: #fff;
  }
  &--date {
    font-size: 16px;
    background: #fff;
    color: var(--color--text);
    text-transform: none;
    height: 28px;
    line-height: 28px;
    padding: 0 12px;
    border-radius: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 32px);
  }
}
