/* ==========================================================================
   Utilities
     > Weight
   ========================================================================== */

.u-w--300 {
  font-weight: 300;
}

.u-w--400 {
  font-weight: 400;
}

.u-w--500 {
  font-weight: 500;
}

.u-w--600 {
  font-weight: 600; //semi-bold
}

.u-w--700 {
  font-weight: 700;
}
