.c-document {
  display: flex;
  align-items: center;
  gap: 8px 16px;
  padding-block: 16px;

  .c-label {
    flex: 0 0 auto;
    margin-right: 0;
    margin-left: auto;
  }

  .far {
    margin-left: 16px;
  }

  @media (hover) {
    &:hover {
      > span:not(.c-label) {
        color: var(--color--primary--hover);
        text-decoration: underline;
      }
    }
  }

  @include bp-max-medium {
    flex-wrap: wrap;
    .c-label {
      order: -1;
      margin-left: 0;
    }
    span:first-of-type {
      flex: 0 0 100%;
    }
  }
}
