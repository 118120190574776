/* ==========================================================================
   #TABLE
   ========================================================================== */

.c-table--responsive,
.tableContainer {
  @include t-table--responsive;
}

// Base / Standard

.c-table,
table {
  min-width: 720px;

  th {
    color: #fff;
    background: var(--color--primary);
    text-align: left;
    padding: 8px 16px;
    font-weight: 400;
    border: 4px solid #fff;

    @include bp-medium {
      padding: 16px 24px;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }
  }

  thead th {
    font-weight: 700;
  }

  tbody th {
    font-weight: 700;
  }

  td,
  th[scope='row'] {
    color: #444;
    padding: 8px 16px;
    background: var(--color--grey--lighter);
    border: 4px solid #fff;

    @include bp-medium {
      padding: 16px 24px;
    }
  }

  td {
    h4 {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  // Bordered

  &.c-table--bordered {
    @include t-table--bordered;
  }

  // Compact

  &.c-table--compact {
    @include t-table--compact;
  }

  // Striped (even row bg, no lines)

  &.c-table--striped {
    @include t-table--striped;
  }

  // Outline

  &.c-table--outline {
    @include t-table--outline;
  }
}
