/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
  border-top: 1px solid var(--color--grey--light);
  background-color: var(--color--grey--lighter);

  .c-logo {
    width: 200px;
    margin-top: 24px;
    margin-bottom: 6px;

    @include bp-medium-large {
      margin-top: 0;
    }

    @include bp-xlarge {
      width: 248px;
      margin-bottom: 0;
    }
  }

  .c-footer--primary {
    padding-top: 30px;
    padding-bottom: 25px;

    @include bp-max-medium {
      .col {
        float: none;
      }
    }
    @include bp-medium {
      display: flex;
      flex-wrap: wrap;
      display: none;
      padding-top: 57px;

      .col {
        margin-left: 0 !important;
      }
    }

    &.active {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 100vw;
      border-top: 1px solid var(--color--grey--light);
    }
  }

  h3 {
    position: relative;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;

    @include bp-large {
      margin-bottom: 24px;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      color: var(--color--text);
      background: transparent;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: right;
      height: var(--tap-target__size);

      &[aria-expanded='true'] .far {
        transform: rotate(180deg);
      }

      @include bp-medium {
        display: none;
      }
    }

    a {
      font-weight: 500;
      text-decoration: none;

      @include bp-medium {
        font-weight: 400;
      }
    }

    &.active span::before {
      content: '\ed8c';
    }
  }

  .footer-menu ul {
    display: none;
    margin-top: 16px;
    margin-bottom: 0;

    @include bp-medium {
      display: block;
      margin-bottom: 0;
    }

    &.active {
      display: block;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin: 0;
    padding: 0;
    font-size: 18px;

    @include bp-medium {
      column-gap: 35px;
      border-bottom: 0;
    }

    li {
      margin-bottom: 15px;
      font-size: 16px;
      list-style-type: none;

      @include bp-large {
        font-size: 18px;
      }

      a {
        text-decoration: none;

        @media (hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.c-footer-social {
      column-gap: 0px;
      font-size: 0px; // Removes whitespace
      margin: 0;
      padding: 0;

      @include bp-xxlarge {
        justify-content: flex-end;
      }

      li {
        display: inline-flex;
        margin: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          width: 38px;
          margin: 0 8px 0 0;
          color: var(--color--primary);
          font-size: 20px;
          border-radius: 50%;

          @media (hover) {
            &:hover {
              background: var(--color--primary);
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .footer-button {
    display: none;

    @include bp-medium {
      position: absolute;
      bottom: 0;
      left: calc(100vw - (100vw - 100%) - 100px);
      display: block;
      width: 100px;
      height: 100%;
      padding: 0 8px;
      font-size: 24px;
      text-align: center;
      background: transparent;
      border-left: 1px solid var(--color--grey--light);

      &:focus-visible {
        outline: none;
        box-shadow: inset 0px 0px 0px 2px var(--color--primary);
      }

      .sr-only {
        color: var(--color--text);
      }

      .fal {
        color: var(--color--text);
        margin-left: 0;
      }

      &.active {
        .fal {
          transform: rotateX(180deg);
        }
      }
    }
  }
}

// Secondary

.c-footer--secondary {
  font-size: 16px;
  margin: 0;
  padding-bottom: 16px;
  height: 100%;

  .row {
    margin-top: 16px;
    margin-bottom: 0;

    @include bp-xlarge {
      display: flex;
    }
  }

  .col:last-child {
    margin-bottom: 0;
  }

  @include bp-medium {
    padding-top: 4px;
    padding-right: 80px;
  }

  @include bp-medium-large {
    display: flex;
    flex-direction: column;

    .col {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  @include bp-full {
    padding-right: 0;
  }

  ul {
    line-height: 2.2;

    @include bp-xlarge {
      margin-top: 2px;
    }

    li {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  p {
    margin-bottom: 0;
    color: var(--color--grey--dark);
    font-size: 14px;
  }
}
