/* ==========================================================================
   Utilities
     > Sticky
   ========================================================================== */

[data-article-meta='sticky'] {
  @include bp-xxlarge {
    position: sticky;
    top: 24px;
    transform: translate(calc(-100% - var(--gutter)), 8px);
    width: 240px;

    .c-article__meta {
      position: absolute;
    }
  }

  @include bp-full {
    width: 320px;
  }
}
