/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: var(--color--text);
  text-decoration: none;
}

// Main Article

.c-article--main {
  .c-article__body {
    a {
      text-decoration: underline;

      &.c-btn,
      &.c-link--cover {
        text-decoration: none;
      }

      @media (hover) {
        &:hover {
          color: var(--color--primary--hover);
        }
      }
    }
  }
}

// Link List

.c-link-list {
  .col:last-child {
    margin-bottom: 0;
  }

  h4 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 16px;

    @include bp-large {
      margin-bottom: 24px;
    }
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 0.5rem;

      a {
        color: var(--color--text);
        text-decoration: none;

        &:hover {
          @include t-link__hover;
        }
      }
    }
  }

  @include bp-medium-large {
    .col {
      margin-bottom: 0 !important;
    }
  }
}
