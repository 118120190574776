/* ==========================================================================
Components
  > Quick Links
========================================================================== */

.c-quicklinks {
  margin: 0 auto;
  width: 100%;
  max-width: calc(1280px + var(--gutter) * 2);

  .row {
    display: flex;
    @include t-flex--wrap;
    margin-bottom: 0;

    .xs12.m6.l3,
    .xs12.m6.l4 {
      @include bp-large {
        .c-article--main.col.xs12.l8.offset-xl1 & {
          width: 50%;
          margin-bottom: var(--gutter) !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .xs12.m6.l3 {
      @include bp-large {
        .c-article--main.col.xs12.l8.offset-xl1 & {
          &:nth-last-child(2) {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .c-quicklink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
    padding: 16px 32px;
    border-radius: 4px;

    @include bp-small {
      flex-direction: row;
      padding-left: 40px;
    }

    @include bp-xlarge {
      min-height: 120px;
    }

    &:focus-visible {
      .c-quicklink__heading {
        text-decoration: underline;
      }
    }

    @media (hover) {
      &:hover {
        .c-quicklink__heading {
          text-decoration: underline;
        }
        .c-article--main & {
          text-decoration: none;
        }
      }
    }

    .far {
      font-size: 48px;
      font-weight: 300;
      @include bp-small {
        order: 2;
      }

      &:before {
        position: relative;
        top: 3px;
      }
    }

    &__heading {
      font-size: 20px;
      margin-bottom: 0;

      @include bp-max-small {
        text-align: center;
      }
    }
  }
}
