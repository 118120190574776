/* ==========================================================================
Elements
  > Description Lists
========================================================================== */

dl {
  dt {
    font-weight: 700;
  }
}
