/* ==========================================================================
Components
  > Alerts
========================================================================== */

.c-alert {
  position: relative;
  line-height: 1.4;
  text-align: center;
  font-size: 1rem;
  background: #fff;
  border-bottom: 1px solid var(--color--grey--light);

  .o-container {
    @include bp-large {
      padding-inline: 96px;
    }
  }

  .c-label {
    line-height: 1;
  }

  a {
    display: flex;
    justify-content: center;
    gap: 12px;
    color: var(--color--primary);

    @include bp-max-small {
      flex-direction: column;
      gap: 8px;
      font-size: 16px;
    }

    @include bp-medium {
      gap: 16px;
    }
  }

  // Icons

  .fal {
    font-size: 24px;

    .fa-times {
      margin-right: 0;
    }
  }
}
