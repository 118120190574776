/* ==========================================================================
Elements
  > Headings
========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).

   XD to CSS character spacing:

   https://codepen.io/raunaqpatel/full/bGpwBvo

 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include t-heading--base;
}

h1,
.h1,
.h-h1 {
  @include t-heading--h1;
}

h2,
.h2,
.h-h2 {
  @include t-heading--h2;
}

.h2--alt {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;

  @include bp-medium {
    font-size: 48px;
    line-height: 56px;
  }
}

h3,
.h3,
.h-h3 {
  @include t-heading--h3;
}

h4,
.h4,
.h-h4 {
  @include t-heading--h4;
}

h5,
.h5,
.h-h5 {
  @include t-heading--h5;
}

h6,
.h6,
.h-h6 {
  @include t-heading--h6;
}
