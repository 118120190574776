/* ==========================================================================
   Tools
     > Scrollbar
   ========================================================================== */

@mixin t-scrollbar(
  $thumb-color: var(--color--primary),
  $track-color: var(--color--grey--light)
) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: 100px;
  }

  // For Firefox
  scrollbar-color: $thumb-color $track-color;
  scrollbar-width: thin;
}
