/* ==========================================================================
Components
  > Navigation
========================================================================== */

// Main Nav

.c-main-nav {
  --fluid--16-24--1280-1600: clamp(1rem, -1rem + 2.5vw, 1.5rem);
  --nav-item-padding-inline: var(--fluid--16-24--1280-1600);

  display: none;

  @include bp-xlarge {
    display: flex;

    > ul {
      display: flex;
      margin: 0;

      > li {
        display: inline-flex;
        flex-shrink: 0;

        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 1.125rem;
          font-family: var(--font--body);
          font-weight: 600;
          position: relative;
          padding-inline: var(--nav-item-padding-inline);
          padding-block: 1rem;

          &:hover {
            text-decoration: none !important;
          }

          &:focus-visible {
            outline: none;

            &:after {
              @include t-nav-item__border;
            }
          }

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 11;
            width: 100%;
            height: calc(100% + 1px);
          }
        }

        > button {
          display: block;
          align-items: center;
          justify-content: center;
          background: none;
          border: 0;
          font-size: 12px;
          padding: 0;
          pointer-events: none;
          margin: 0 16px 0 -24px;

          .far {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            margin-left: 0px;
          }

          &:focus-visible {
            outline: none;

            .far {
              outline: 2px solid var(--color--primary);
              border-radius: 100%;
              outline-offset: -6px;
            }
          }

          &[aria-expanded='true'] {
            .far {
              transform: rotate(180deg);
            }
          }
        }

        ul li {
          a,
          button {
            &:hover,
            &:focus-visible,
            &.active,
            &.this {
              outline: none;
              // color: var(--color--primary);
              background-color: var(--color--primary--lightest);
            }
          }
        }
      }
    }
  }
}

.c-main-nav > ul > li {
  &.active,
  &.c-menu__item--active {
    // Bottom border

    > a {
      &:after {
        @include t-nav-item__border;
      }
    }

    .far fa-chevron-down {
      transform: rotate(180deg);
    }
  }
}

// Mega Menu

.c-mega-menu {
  display: none;
  position: absolute;
  width: 100vw;
  background: #fff;
  top: 100%;
  left: 0;
  bottom: auto;
  z-index: 9;
  border-top: 1px solid var(--color--border);
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.16);
  font-size: initial;

  .o-container {
    @include bp-xlarge {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0 64px;
    }

    @include bp-xxlarge {
      padding: 0 var(--gutter);
    }
  }

  .close-button--mega {
    position: absolute;
    top: 24px;
    right: 48px;
    font-size: 20px;
    background: transparent;
    font-family: var(--font--headings);
    order: 2;
    padding: 0 8px;
    width: var(--tap-target__size);
    height: var(--tap-target__size);
    z-index: 1;

    @include bp-xxlarge {
      right: 64px;
    }

    &:hover {
      background-image: none !important;
      background-color: #f5f5f5;
    }

    &:focus-visible {
      background: transparent;
    }
  }

  // Header

  &__heading {
    display: flex;
    padding: 52px 0 16px 0;

    // Section

    section {
      width: 66.6666666667%;

      .h2 {
        font-weight: 700;
        letter-spacing: -0.88px;
        margin: 0 0 12px var(--gutter);
        @include bp-xxlarge {
          font-size: 48px;
        }
      }

      p:not(.h2) {
        font-size: 24px;
        line-height: 34px;
        margin: 0;
      }
    }
  }

  // Hold
  .c-menu-footer__hold {
    display: flex;
    overflow: hidden;
    margin-block: 24px;
  }

  // Footer

  footer {
    width: 100%;
    padding: var(--gutter) 0;
    overflow: hidden;

    figure {
      margin-bottom: 24px;
    }

    .c-btn {
      width: 100%;
    }
  }
}

// Menu Levels

.c-menu__levels {
  display: flex;
  flex-shrink: 0;
  width: calc(100% - 36px);

  .c-menu__level {
    position: relative;
    width: calc(33.333% - var(--gutter) / 3);
    height: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;

      a,
      button {
        display: block;
        position: relative;
        font-size: 1rem;
        width: 100%;
        text-align: left;
        background: transparent;
        height: 40px;
        line-height: 40px;
        border: none;
        border-radius: 0.5rem;
        margin: 0;
        padding: 0 30px 0 var(--gutter);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.c-nav__item--alt {
          text-transform: uppercase;
          font-size: 13px;
          border-top: 1px solid var(--color--border);
        }
      }

      .fa-chevron-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        text-align: center;
        font-size: 14px;
        width: 40px;
        height: var(--tap-target__size);
        line-height: var(--tap-target__size);
      }

      li.c-nav__item--alt {
        border-top: 1px solid var(--color--border);
        margin-top: 8px;
        padding-top: 8px;

        a {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.64px;
        }
      }
    }

    &.c-menu__level--2 {
      &:after {
        display: none;
      }
    }
  }
}

// Split e.g. Locations

.c-menu--split--02 {
  column-count: 2;
  column-gap: 0;
}

.c-menu--split--03 {
  column-count: 3;
  column-gap: 0;
}

.c-menu--split--span {
  column-span: all;
}

.c-main-nav > ul > li.active {
  .c-mega-menu {
    display: block;
  }
}

// Drop Menu

.drop-menu {
  position: relative;

  > ul {
    @include t-drop-menu--ui;
    top: calc(100% + 1px);
    left: 15px;

    @include bp-xxlarge {
      left: 24px;
    }
  }

  &[data-menu-position="right"] {
    > ul {
      left: unset;
      right: 24px;
    }
  }

  > [aria-expanded="true"] {
    + ul {
      display: block;
    }
  }
}

// ADA Mega Menu

.c-ada-mega-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  background: #fff;
  top: 176px;
  left: 0;
  height: auto;
  bottom: auto;
  z-index: 9;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .o-container {
    padding: 3.5rem 25px;
    position: relative;

    .c-ada-mega-menu--close {
      position: absolute;
      right: 2rem;
      top: 2rem;
      font-size: 1.2rem;
      background: none;
      border: 0;
      cursor: pointer;

      &:focus-visible,
      &:hover {
        outline: 2px dotted var(--color--primary);
      }
    }

    .c-ada-mega-menu--intro {
      float: left;
      width: 40%;
      padding-right: 10rem;
    }

    .c-ada-mega-menu--links {
      float: left;
      width: 60%;

      ul {
        list-style-type: none;
        margin: 0;
        column-count: 3;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .row {
      float: left;
      width: 60%;
      margin-bottom: 0;

      .col {
        h3 {
          font-size: 20px;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
      }
    }
  }
}

.c-menu__level--1 .ada-menu-mega,
.c-menu__level--2 .ada-menu-mega {
  display: none;
}

.ada-menu-mega .has--label {
  position: relative;

  > a {
    padding-right: 100px !important;
  }

  > ul {
    display: inline-flex !important;
    position: absolute;
    top: 0;
    right: 8px;

    > li {
      display: flex;
      align-items: center;

      > .c-label {
        transition: border-radius 100ms ease-in;
        background: var(--color--primary);
        font-size: 12px;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
