/* ==========================================================================
   #MINICARD
   ========================================================================== */

.c-mini-card {
  display: block;
  padding: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.16);

  @media (hover) {
    &:hover {
      figcaption {
        color: var(--color--primary--hover);
      }
    }
  }

  figure {
    align-items: flex-start;
    gap: 16px 24px;
    margin-bottom: 0;
  }

  .c-figure__img {
    flex: 0 0 80px;
  }
  figcaption {
    width: auto;
    padding: 0;
    border-left: 0;
    font-size: 16px;
  }

  .c-figure__hold {
    width: 80px;
  }

  // min-height: 100px;
  // width: 100%;
  // padding: 2rem;
  // box-shadow: 0 10px 27px rgba(0,0,0,0.1);
  // margin-bottom: 0.6rem;

  // p {
  //   margin-bottom: 1rem;
  // }

  // h4 {
  //   margin-bottom: 1rem;
  // }

  // &.large-title h4 {
  //   font-size: 1.5rem;
  //   color: var(--color--secondary);
  // }
}
