/* ==========================================================================
Components
  > Article
    > Meta
========================================================================== */

.c-article__meta {
  .c-meta--highlight {
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px 10px 0;
    background: var(--color--primary);
    display: inline-block;
    color: #fff;
    padding: 2px 8px;
  }

  &--bg {
    display: inline-flex;
    padding: var(--gutter);
    background-color: var(--color--primary--lightest);
    border-radius: 8px;

    .c-list--meta {
      margin-top: 0;
    }

    a {
      color: var(--color--primary);

      .c-article__body & {
        text-decoration: none;
      }

      @media (hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.c-article__meta--event {
    .c-list--meta {
      margin-top: 0;
    }
  }
}

.c-article--main {
  // 3 col

  &.l5 {
    .c-article__meta--event {
      .col {
        &:first-child {
          margin-bottom: 8px;

          @include bp-medium {
            margin-bottom: var(--gutter);
          }

          @include bp-large {
            margin-bottom: 8px;
          }

          @include bp-xlarge {
            margin-bottom: var(--gutter);
          }
        }

        @include bp-large {
          width: 100%;
        }

        @include bp-xlarge {
          width: 50%;
        }
      }
    }
  }
}
