/* ==========================================================================
Tools
  > Spacing
========================================================================== */

// Let's output some useful margin and padding options @ 8pt increments

@for $i from 0 through 8 {
  // Margin

  .u-mt--#{$i} {
    margin-top: #{$i * 8}px !important;
  }

  .u-mr--#{$i} {
    margin-right: #{$i * 8}px !important;
  }

  .u-mb--#{$i} {
    margin-bottom: #{$i * 8}px !important;
  }

  .u-ml--#{$i} {
    margin-left: #{$i * 8}px !important;
  }

  // Padding

  .u-p--#{$i} {
    padding: #{$i * 8}px !important;
  }

  .u-pt--#{$i} {
    padding-top: #{$i * 8}px !important;
  }

  .u-pr--#{$i} {
    padding-right: #{$i * 8}px !important;
  }

  .u-pb--#{$i} {
    padding-bottom: #{$i * 8}px !important;
  }

  .u-pl--#{$i} {
    padding-left: #{$i * 8}px !important;
  }
}
