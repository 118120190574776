/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/* Col Header */

.c-col-header {
  background: var(--color--tertiary);
  color: #fff;
  font-weight: normal;
  padding: 0.7rem 1rem;
  font-size: 1.3rem;
}

/* Col Header - FAQ */

@include bp-medium {
  .c-col-header__faq {
    background-image: url('../assets/images/icon-help@2x.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position-x: 24px;
    background-position-y: 16px;
    padding-left: 70px;
    background-position-y: 10px;
  }
}

@include bp-large {
  .c-col-header__faq {
    background-position-y: 14px;
  }
}

.c-heading--sidebar-nav {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0 0 16px 24px;
}

.c-event--details {
  .h4 {
    @include t-heading--h5;
  }

  p {
    margin-bottom: 0;
  }
}

// Widgets

.c-widget__header {
  margin-bottom: 40px;

  header {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @include bp-max-small {
      flex-wrap: wrap;
    }

    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0;
      line-height: 1.5;
    }

    .c-btn {
      flex: 0 0 auto;
      align-self: flex-start;
    }
  }

  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

// Widgets - Aside

aside {
  .c-widget__heading {
    @include t-heading--h4;
    text-align: left;
    margin-bottom: 16px;
  }
}

// If bg colour

aside {
  .c-widget__heading {
    color: #fff;
  }
}

// Minor

.c-heading--minor {
  position: relative;
  font-size: 20px;
  letter-spacing: 0;
  font-weight: 600;

  @include bp-large {
    font-size: 24px;
  }
}

// Minor - In Aside

@include bp-large {
  aside {
    .c-heading--minor {
      &:before {
        width: calc(100% + 160px);
        left: -160px;
      }
    }
  }
}

// Style Guide

.c-heading--style-guide {
  font-size: 20px;
  font-weight: 400;
}

// Bordered

.c-heading--borderd {
  border-bottom: 1px solid var(--color--grey);
  padding-bottom: 8px;
  margin-bottom: 32px;
}

// Bordered - Style Guide

.c-style-guide .c-heading--borderd {
  display: flex;
  flex-direction: column;

  span {
    @include t-heading--h4;
    margin-bottom: 8px;
  }

  @include bp-small-medium-extended {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      order: 2;
      margin-bottom: 0;
      margin-left: var(--gutter);
      text-align: right;
    }
  }
}

// Sidebar

.o-sidebar--r .h4 {
  @include t-heading--h4;
  font-size: 22px !important;
  color: #343434 !important;
}

// Major - h2

h2.c-heading--major {
  color: var(--color--primary--light);
  letter-spacing: -1.386px;
  margin-bottom: 0;
  font-size: 42px;
  line-height: 48px;

  @include bp-medium {
    letter-spacing: -1.792px;
    font-size: 56px;
    line-height: 64px;
  }

  @include bp-xlarge {
    font-size: 80px;
    line-height: 85px;
    letter-spacing: -2.64px;
  }
}

// Major - h3

h3.c-heading--major {
  font-family: var(--font-body);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.192px;
  margin-bottom: 0;

  @include bp-medium {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.08px;
  }
}

// Mini

.c-heading--mini {
  margin-left: 8px;
  margin-bottom: 0;
  margin-right: auto;
  font-size: 18px;

  @media (min-width: 360px) {
    margin-left: 16px;
    font-size: 22px;
  }
}

// Form

.c-heading--form {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.224px;
  margin-bottom: 32px;
  text-align: center;

  @include bp-medium-large {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0;
    margin-bottom: 24px;
  }
}

// Dynamic

.c-heading--dynamic {
  @include bp-max-medium {
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  @include bp-medium {
    color: var(--color--primary--light);
    border-top: 2px solid var(--color--text);
    padding-top: calc(32px - 8px);
  }
}
