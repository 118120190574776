/* ==========================================================================
Utilities
  > Align
========================================================================== */

.u-fl--none {
  float: none !important;
}

.u-float-left--always {
  float: left;
}

@include bp-small-medium {
  .u-float-left--for-s-m {
    float: left;
  }

  .u-float-right--for-s-m {
    float: right;
  }
}

@include bp-medium {
  .u-float-left {
    float: left;
  }

  .u-float-right {
    float: right;
  }

  .u-ta--c--m {
    text-align: center;
  }
}

.u-fl--l {
  float: left;
}

.u-fl--r {
  float: right;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-fl--l--m--max--l {
    float: left;
  }
}

.u-text-center {
  text-align: center;

  ul,
  ol {
    list-style-position: inside;
  }
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

@include bp-medium-large {
  .u-ta--r--ml {
    text-align: right;
  }
}

@include bp-large {
  .u-ta--r--l {
    text-align: right;
  }

  .u-fl--r--l {
    float: right;
    margin: 0 0 8px 16px;
  }
}

@include bp-max-large {
  .u-text-center-for-max-m {
    text-align: center;
  }
}

.u-cols--2 {
  columns: 2;
}

// Text -- centre

.u-ta--c {
  text-align: center;
}

// Text - l

.u-ta--l {
  text-align: left;
}

// Text - r

.u-ta--r {
  text-align: right;
}

// Text centre @max l

@include bp-max-large {
  .u-ta--c--max--l {
    text-align: center;
  }
}

@include bp-small {
  .u-ta--l--s {
    text-align: left;
  }

  .u-ta--r--s {
    text-align: right;
  }
}

/*
  CTA Align
    Ensures Card CTAs align to bottom
*/

// @m

@include bp-medium {
  .u-cta--align--m {
    @include t-cta--align;
  }
}
// @l

@include bp-large {
  .u-cta--align--l {
    @include t-cta--align;
  }
}

// @xl

@include bp-xlarge {
  .u-cta--align--xl {
    @include t-cta--align;
  }
}
