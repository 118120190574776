/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 2rem;
}

pre {
  display: flex;
  background-color: var(--color--grey--lighter);
  padding: var(--spacer);
  border-radius: 16px;
  font-size: 16px;
  margin: var(--gutter) auto 112px auto;
}

/**
 * Consistent indentation for lists.
 */

dd,
ol,
ul {
  margin-left: 1.5rem;
}

ul,
ol {
  li {
    &::marker {
      letter-spacing: -1px;
    }
  }
}
