/* ==========================================================================
Utilities
  > Aspect Ratios
========================================================================== */

// 3:2 - Default

.u-aspect,
.u-aspect--3-2 {
  @include fluid-aspect(3 2);
  background: var(
    --color--grey--light
  ); // No Cumulative Layout Shift (CLS) - Provide placeholder bg colour for slower connections
}

.u-aspect--4-3 {
  @include fluid-aspect(4 3);
}

.u-aspect--3-1 {
  @include fluid-aspect(3 1);
}

.u-aspect--2-3 {
  @include fluid-aspect(2 3);
}

.u-aspect--16-9 {
  @include fluid-aspect(16 9);
}

.u-aspect--5-6 {
  @include fluid-aspect(5 6);
}

.u-aspect--1-1 {
  @include fluid-aspect(1 1);
}
