/* ==========================================================================
Utilities
  > Column Count
========================================================================== */

[class^='u-col-count'] {
  column-gap: var(--gutter);
}

.u-col-count {
  .col {
    float: none;
    break-inside: avoid;
  }
}

// 2

.u-col-count--02 {
  column-count: 2;
}

@include bp-xsmall {
  .u-col-count--02--xs {
    column-count: 2;
  }
}

@include bp-small-medium {
  .u-col-count--02--sm {
    column-count: 2;
  }

  .u-col-count--03--sm {
    column-count: 3;
  }
}

@include bp-medium-large {
  .u-col-count--03--ml {
    column-count: 3;
  }
}

@include bp-large {
  .u-col-count--02--l {
    column-count: 2;
  }

  .u-col-count--03--l {
    column-count: 3;
  }
}
