/* ==========================================================================
Components
  > Search
    > Predictive
========================================================================== */

.predictive-container {
  display: none;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  overflow-y: scroll;
  justify-content: flex-end;
  padding-inline: var(--header-padding-inline);
  padding-block: 4rem 2rem;

  .is--search & {
    display: flex;
  }
 }

.results {
  display: none;
  @include shadow(4);
  font-size: 1rem;
  max-width: 40rem;
  height: fit-content;
  background: #fff;
  border-radius: 0.5rem;
  list-style-type: none;
  margin: 0;
  padding: 16px;

  .is--search & {
    display: block;
  }

  li {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border-radius: 0.5rem;
    color: #222;
    display: block;

    &:hover,
    &.is-active {
      text-decoration: none;
      color: #fff !important;
      background: var(--color--primary);
    }

    &.suggested {
      padding-left: 32px;
      font-weight: 700;
      color: #111;

      &:before {
        content: '';
        background: var(--color--primary);
        width: 8px;
        height: 8px;
        position: absolute;
        left: 12px;
        top: 16px;
        border-radius: 50%;
      }

      &:hover {
        color: #fff;

        &:before {
          background: #fff;
        }
      }

      &.is-active:before {
        background: #fff;
      }
    }
  }

  &.c-results--absolute {
    position: absolute;
    margin: -16px auto 0 auto;
  }
}

.is-visible > .results {
  display: block;
}