/* ==========================================================================
   Tools
     > Form -- UI
   ========================================================================== */

@mixin t-form--ui {
  background: var(--color--primary--lightest);
  border: none;
  padding: 0 24px;
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-family: var(--font--body);
  font-weight: 400;
  color: var(--color--text);
  border-radius: var(--global__radius);

  .u-bg-color--primary--lightest & {
    background-color: #fff;
  }

  @media (max-width: 374px) {
    font-size: 18px;
  }
}

/*
  Floated Labels:
    https://github.com/tonystar/float-label-css
    https://github.com/tonystar/float-label-css/blob/master/scss/_mixins.scss
*/

@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  label {
    pointer-events: none;
    position: absolute;
    top: -25px;
    left: 0;
    right: 24px;
    font-size: 14px;
    cursor: text;
    transition: all 0.2s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 1.5;
    @content;
  }
}

@mixin float-label--l {
  label {
    top: -40px;
    font-size: 18px;
  }
}

@mixin float-label--s {
  label {
    top: -24px;
    font-size: 16px;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 0;
    transition: all 0.2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
    @content;
  }
}
