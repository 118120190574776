.c-wysiwyg {
  @include t-word-wrap;

  h2 {
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 0;
  }
  a:not(.c-btn) {
    text-decoration: underline;
    color: var(--color--primary);

    @media (hover) {
      &:hover {
        color: var(--color--primary--hover);
      }
    }
  }

  .bullets-blue {
    li {
      &::marker {
        color: var(--color--primary);
      }
    }
  }
}
