/* ==========================================================================
   Utilities
     > Themes

     data-component__theme [light dark]
   ========================================================================== */

// Dark

[data-component__theme='dark'] {
  * {
    color: #fff !important;

    &:focus-visible {
      outline-color: #fff;
    }
  }

  .c-btn--outline {
    @include t-btn--outline--light;
  }
}

// Light

[data-component__theme='light'] {
  * {
    color: var(--color--text);
  }

  .c-btn {
    //color: var(--color--primary);
    .far,
    .fal {
      color: #fff;
    }
  }

  .u-text-protection:before {
    display: none;
  }
}
